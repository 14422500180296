/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { bool, array, func, number, string } from 'prop-types'
import { prop, toLower, compose } from 'ramda'
import LazyLoad from 'react-lazyload'

import AlwaysFixed from 'components/AlwaysFixed'
import Icon from 'components/Icon'
import Link from 'components/Link'
import RubleSign from 'components/RubleSign'
import Wrapper from 'components/Wrapper'
import { Security } from 'containers/FormIo'
import { setAnalyticsProps } from 'helpers/metaTags'
import ScrollUpBtn from 'UI/ScrollUpBtn'
import formatAmount from 'utils/formatAmount'
import noun from 'utils/noun'

import styles from './Footer.scss'

const HEIGHT_TOP = 231

export default class Footer extends PureComponent {
  static propTypes = {
    isUserWithoutPassword: bool,
    showModal: func,
    isDisabledBodyScroll: bool,
    basketCount: number,
    basketSum: number,
    kancobozId: string,
    isLoyalty: bool,
    isLoyaltyAccess: bool,
    isLoyaltyMember: bool,
    isBookmark: bool,
    phone: string,
    address: string,
    name: string,
    description: string,
    landingUrl: string,
    vkUrl: string,
    fbUrl: string,
    instagramUrl: string,
    marks: array,
    hasAccessOffices: bool,
    hasAccessRegistration: bool
  }

  static defaultProps = {
    basketCount: 0,
    kancobozId: '',
    vkUrl: '',
    fbUrl: '',
    instagramUrl: '',
    basketSum: 0,
    isLoyaltyMember: false,
    hasAccessOffices: true,
    isLoyaltyAccess: false,
    hasAccessRegistration: true,
    marks: [],
    phone: ''
  }

  handleOpenRegistrationForm = () => this.props.showModal('registration')

  getEndScrollPosition = () => {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      return document.body.clientHeight - window.innerHeight - HEIGHT_TOP
    }
    return 0
  }

  render() {
    const {
      isUserWithoutPassword,
      isDisabledBodyScroll,
      isBookmark,
      isLoyalty,
      isLoyaltyAccess,
      isLoyaltyMember,
      basketCount,
      basketSum,
      phone,
      address,
      name,
      description,
      marks,
      landingUrl,
      hasAccessOffices,
      hasAccessRegistration,
      kancobozId,
      vkUrl,
      instagramUrl
    } = this.props
    let linkToBasket = isLoyalty ? '/basket' : '/loyalty/basket'

    if (isLoyaltyAccess && !isLoyaltyMember) {
      linkToBasket = '/loyalty/how_it_works'
    }
    const TagLazy = typeof window === 'undefined' ? 'div' : LazyLoad

    const analyticProps = setAnalyticsProps({
      type: 'action',
      group: 'navigation',
      pos: 'footer'
    })

    return (
      <TagLazy height={260}
        offset={500}
        once>
        <footer
          className={styles.footer}
          itemScope
          itemType='http://schema.org/WPFooter'
        >
          <div
            className={cx(styles.container, {
              [styles.containerBookmark]: isBookmark,
              [styles.containerLoyalty]: isLoyalty
            })}
            ref={this.setFooter}
          >
            <AlwaysFixed
              id='footer'
              isModal={false}
              scrollPosition={100}
              getEndScrollPosition={this.getEndScrollPosition}
              isDisabledScroll={isDisabledBodyScroll}
              isHideBeforeScroll
              isBottom
              isClickThrough
            >
              <ScrollUpBtn isLoyalty={isLoyalty}
                isBookmark={isBookmark} />
            </AlwaysFixed>

            <Wrapper className={styles.wrapper}>
              <div className={styles.aboutContent}
                itemProp='about'>
                <ul
                  className={cx({
                    [styles.info]: true,
                    [styles.infoBookmark]: isBookmark
                  })}
                >
                  <li
                    className={styles.item}
                    itemProp='itemListElement'
                    itemScope
                    itemType='http://schema.org/ItemList'
                  >
                    <h3 className={styles.title}
                      itemProp='name'>
                      {name}
                    </h3>

                    <span className={styles.text}
                      itemProp='description'>
                      {description}
                    </span>
                  </li>

                  <li
                    className={styles.item}
                    itemProp='itemListElement'
                    itemScope
                    itemType='http://schema.org/ItemList'
                  >
                    <h3 className={styles.phone}>
                      <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: phone }}
                        itemProp='telephone'
                      />
                    </h3>
                    <span className={styles.text}
                      itemProp='streetAddress'>
                      {address}
                    </span>
                  </li>

                  {kancobozId && (
                    <li
                      className={styles.lastItem}
                      itemProp='itemListElement'
                      itemScope
                      itemType='http://schema.org/ItemList'
                    >
                      <a href={`https://kanzoboz.ru/?rate=${kancobozId}`}>
                        <img
                          src={`https://rating.kanzoboz.ru/?id=${kancobozId}`}
                          width='88'
                          height='31'
                          alt='Рейтинг канцелярских компаний'
                          itemProp='image'
                        />
                      </a>
                    </li>
                  )}
                </ul>
              </div>

              <div className={styles.menuContent}>
                <ul className={styles.menuWrapper}>
                  <li
                    className={styles.menuWrapperElement}
                    itemProp='itemListElement'
                    itemScope
                    itemType='http://schema.org/ItemList'
                  >
                    <h3 className={styles.title}>Каталоги</h3>
                    <ul className={styles.menu}>
                      <li
                        itemProp='itemListElement'
                        itemScope
                        itemType='http://schema.org/ItemList'
                      >
                        <Link to='/catalog'
                          color='white'
                          {...analyticProps}>
                          Каталог товаров
                        </Link>
                      </li>
                      <li
                        itemProp='itemListElement'
                        itemScope
                        itemType='http://schema.org/ItemList'
                      >
                        <Link to='/brand'
                          color='white'
                          {...analyticProps}>
                          Бренды
                        </Link>
                      </li>
                      {marks.map(item => {
                        const code = compose(toLower, prop('CODE'))(item)
                        return (
                          <li
                            key={prop('CODE', item)}
                            itemProp='itemListElement'
                            itemScope
                            itemType='http://schema.org/ItemList'
                          >
                            <Link
                              to={`/catalog/mark/${code}`}
                              color='white'
                              {...analyticProps}
                            >
                              {prop('TITLE', item)}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </li>
                  <li
                    className={styles.menuWrapperElement}
                    itemProp='itemListElement'
                    itemScope
                    itemType='http://schema.org/ItemList'
                  >
                    <h3 className={styles.title}>О компании</h3>

                    <ul className={styles.menu}>
                      {hasAccessOffices && (
                        <li
                          itemProp='itemListElement'
                          itemScope
                          itemType='http://schema.org/ItemList'
                        >
                          <Link to='/offices'
                            color='white'
                            {...analyticProps}>
                            Адреса филиалов
                          </Link>
                        </li>
                      )}

                      <li
                        itemProp='itemListElement'
                        itemScope
                        itemType='http://schema.org/ItemList'
                      >
                        <Link
                          to='/content/news'
                          color='white'
                          {...analyticProps}
                        >
                          Новости
                        </Link>
                      </li>
                      {!isUserWithoutPassword && (
                        <li
                          itemProp='itemListElement'
                          itemScope
                          itemType='http://schema.org/ItemList'
                        >
                          <Link
                            to='/content/update'
                            color='white'
                            {...analyticProps}
                          >
                            Обновления
                          </Link>
                        </li>
                      )}
                    </ul>
                  </li>
                  {isUserWithoutPassword && (
                    <li
                      className={styles.menuWrapperElement}
                      itemProp='itemListElement'
                      itemScope
                      itemType='http://schema.org/ItemList'
                    >
                      <h3 className={styles.title}>Новым клиентам</h3>
                      <ul className={styles.menu}>
                        <li
                          itemProp='itemListElement'
                          itemScope
                          itemType='http://schema.org/ItemList'
                        >
                          <a
                            href={landingUrl}
                            className={styles.text}
                            mode='white'
                            {...analyticProps}
                          >
                            Почему мы
                          </a>
                        </li>
                        {hasAccessRegistration && (
                          <li
                            itemProp='itemListElement'
                            itemScope
                            itemType='http://schema.org/ItemList'
                          >
                            <div
                              onClick={this.handleOpenRegistrationForm}
                              className={styles.text}
                              role='presentation'
                              {...analyticProps}
                            >
                              Зарегистрироваться
                            </div>
                          </li>
                        )}
                      </ul>
                    </li>
                  )}
                  <li
                    className={styles.menuWrapperElement}
                    itemProp='itemListElement'
                    itemScope
                    itemType='http://schema.org/ItemList'
                  >
                    <h3 className={styles.title}>Помощь</h3>
                    <ul className={styles.menu}>
                      <li
                        itemProp='itemListElement'
                        itemScope
                        itemType='http://schema.org/ItemList'
                      >
                        <Link
                          to={isLoyalty ? '/loyalty/faq' : '/faq'}
                          color='white'
                          {...analyticProps}
                        >
                          Вопросы и ответы
                        </Link>
                      </li>
                      <li
                        itemProp='itemListElement'
                        itemScope
                        itemType='http://schema.org/ItemList'
                      >
                        <Link to='/sitemap'
                          color='white'
                          {...analyticProps}>
                          Карта сайта
                        </Link>
                      </li>
                      <li
                        itemProp='itemListElement'
                        itemScope
                        itemType='http://schema.org/ItemList'
                      >
                        <Link
                          to='/documents/policy'
                          color='white'
                          {...analyticProps}
                        >
                          Политика конфиденциальности
                        </Link>
                      </li>
                      <li
                        itemProp='itemListElement'
                        itemScope
                        itemType='http://schema.org/ItemList'
                      >
                        <Security />
                      </li>
                    </ul>
                  </li>

                  {!isUserWithoutPassword &&
                    isLoyaltyAccess && (
                    <li
                      className={styles.menuWrapperBasket}
                      itemProp='itemListElement'
                      itemScope
                      itemType='http://schema.org/ItemList'
                    >
                      <div
                        className={cx(styles.basket, {
                          [styles.basketLoyalty]: isLoyalty
                        })}
                      >
                        <span className={cx(styles.basketCartLink, {
                          [styles.basketCartLink_loyalty]: !isLoyalty
                        })}>
                          <Link to={linkToBasket}>
                            <Icon
                              className={cx(styles.basketCart, {
                                [styles.basketCartLoyalty]: !isLoyalty,
                                [styles.basketCartDefault]: isLoyalty
                              })}
                              icon={isLoyalty ? 'cart' : 'cart-loyalty'}
                              isActive={!!basketCount}
                              size='auto'
                            />
                          </Link>
                        </span>
                        {!!basketCount && (
                          <div className={styles.basketInfo}>
                            <div className={styles.basketHint}>
                              <div
                                className={cx(styles.basketCount, {
                                  [styles.basketCountLoyalty]: !isLoyalty,
                                  [styles.basketCountDefault]: isLoyalty
                                })}
                              >
                                {basketCount}
                              </div>
                            </div>

                            <div className={styles.basketSum}>
                              <span>{formatAmount(basketSum)}</span>
                              <span className={styles.basketCurrence}>
                                {isLoyalty ? (
                                  <RubleSign />
                                ) : (
                                  noun(basketSum, ['балл', 'балла', 'баллов'])
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </li>
                  )}
                  <li
                    className={styles.menuWrapperSocial}
                    itemProp='itemListElement'
                    itemScope
                    itemType='http://schema.org/ItemList'
                  >
                    <ul className={styles.menuSocial}>
                      {!!vkUrl && (
                        <li
                          className={cx(styles.social, styles.socialVk, {
                            [styles.socialLoyalty]: isLoyalty
                          })}
                          itemProp='itemListElement'
                          itemScope
                          itemType='http://schema.org/ItemList'
                        >
                          <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href={vkUrl}
                          >
                            Вконтакте
                          </a>
                        </li>
                      )}
                      {instagramUrl && (
                        <li
                          className={cx(styles.social, styles.socialInstagram, {
                            [styles.socialLoyalty]: isLoyalty
                          })}
                          itemProp='itemListElement'
                          itemScope
                          itemType='http://schema.org/ItemList'
                        >
                          <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href={instagramUrl}
                          >
                            Instagram
                          </a>
                        </li>
                      )}
                    </ul>
                  </li>
                </ul>
              </div>
            </Wrapper>
          </div>
        </footer>
      </TagLazy>
    )
  }
}
