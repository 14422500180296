/* eslint-disable complexity */
import R, { propOr } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'
import { setNav } from 'redux/modules/basket'
import { fetchCountSuccess as fetchCountSuccessCompare } from 'redux/modules/compare'
import { fetchCountSuccess as fetchCountSuccessFavorite } from 'redux/modules/favorite'
import { hideModal, showModal } from 'redux/modules/modal'
import {
  // fetchCartsContractorSuccess,
  fetchSuccess as fetchProductListSuccess,
  setCarts
} from 'redux/modules/productList'
import { setUserId } from 'redux/modules/socket'
import { clearToken } from 'redux/modules/token'

import { getIsSuperuser } from './selector'

const initialState = {
  isLoading: false,
  isLoaded: false,
  isChangingContractor: false,
  managers: [],
  support: [],
  offers: [],
  basketProductsLoyalty: [],
  metaTags: [],
  metaHead: {},
  supportLoaded: false,
  title: '',
  name: '',
  address: '',
  description: '',
  siteUrl: '',
  phone: '+7 (4912) 958 000',
  contractors: [],
  error: '',
  status: {},
  linkCabinet918: '',
  isRH: false,
  isLoadingcount: false,
  isLoadedcount: false,
  isLoadingmini: false,
  isLoadedmini: false,
  toContractorId: 0
}

const MILLISECONDS_IN_SECOND = 1000
const SECONDS_IN_MINUTE = 60
const MINUTES_IN_HOUR = 60
const HOURS_IN_DAY = 24
const DAY_IN_MONTH = 31
const COST_20 = 20
const DOMAIN918 = 'https://9-18ok.ru'

export {
  getIsLoaded,
  getSupports,
  getManager,
  getManagers,
  getIsSupportLoaded,
  getIsLoading,
  getTitle,
  getRole,
  getPhone,
  getContractors,
  getActiveContractor,
  getContractorCode,
  userIdSelector,
  getUserEmail,
  getCompanyEmail,
  getIsChangingContractor,
  getIsUser,
  getIsTempUser,
  getMessage,
  getIsAllowed,
  getAddress,
  getName,
  getDescription,
  getSiteUrl,
  getIsSuperuser,
  getDateRegister,
  getAccess,
  getSearchedContractors,
  getIsExistsSuperuser,
  adminTokenSelector,
  getIsLoyaltyVisible,
  isOffersVisibleSelector,
  isRHSelector,
  costMultipleSelector,
  basketsLoadingSelector,
  basketsLoadedSelector,
  getOfficesAccess,
  getRegistrationAccess,
  getKancobozId,
  gtmIdSelector,
  gaIdSelector,
  metaTagsSelector,
  metaTitleSelector,
  metaDescSelector,
  onlyRubSelector,
  discountsAccessSelector,
  vkUrlSelector,
  fbUrlSelector,
  instagramUrlSelector,
  feedbackFormSelector,
  apiAccessSelector,
  tempUserSelector,
  isTableViewSelector,
  bookkeepingAccessSelector,
  offersAccessSelector,
  contractorNameSelectedSelector,
  pretensionAccessSelector,
  linkCabinet918Selector,
  searchAccessSelector,
  storesSelector,
  isStoresSelector,
  currentContractorGuidSelector,
  currentContractorIdSelector,
  currentContractorNameSelector,
  moderationAccessesSelector,
  contractorsSelector,
  addAddressAccessSelector,
  toContractorIdSelector,
  isLoyaltyMember,
  isEdoSelector,
  isButtonProgessSelector,
  personalNameSelector,
  accessNameSelector,
  isPartnerSelector,
  scheduleAccessSelector,
  menuAccessSelector
} from './selector'

export const resetStore = createAction('RESET_STORE')

export const fetch = createAction('personal/GET_INFO')
export const fetchSuccess = createAction('personal/GET_INFO_SUCCESS')
export const fetchFailure = createAction('personal/GET_INFO_FAILURE')
export const setContractor = createAction('personal/SET_ACTIVE_CONTRACTOR')

export const fetchBaskets = createAction('personal/FETCH_BASKETS')
export const fetchBasketsSuccess = createAction(
  'personal/FETCH_BASKETS_SUCCESS'
)
export const fetchBasketsFailure = createAction(
  'personal/FETCH_BASKETS_FAILURE'
)

export const sendReview = createAction('personal/SEND_REVIEW')
export const sendReviewSuccess = createAction('personal/SEND_REVIEW_SUCCESS')
export const sendReviewFailure = createAction('personal/SEND_REVIEW_FAILURE')

export const clearSearchContractor = createAction(
  'support/CLEAR_SEARCH_CONTRACTOR'
)
export const setSearchContractor = createAction('support/SET_SEARCH_CONTRACTOR')
export const searchContractors = createAction('support/SEARCH_CONTRACTORS')
export const searchContractorsSuccess = createAction(
  'support/SEARCH_CONTRACTORS_SUCCESS'
)
export const searchContractorsFailure = createAction(
  'support/SEARCH_CONTRACTORS_FAILURE'
)

export const authContractor = createAction('support/AUTH_CONTRACTOR')
export const authContractorSuccess = createAction(
  'support/AUTH_CONTRACTOR_SUCCESS'
)
export const authContractorFailure = createAction(
  'support/AUTH_CONTRACTOR_FAILURE'
)

export const fetchCabinet918 = createAction('personal/FETCH_CABINET_918')
export const fetchCabinet918Success = createAction(
  'personal/FETCH_CABINET918_SUCCESS'
)
export const fetchCabinet918Failure = createAction(
  'personal/FETCH_CABINET918_FAILURE'
)

export const gotoSuperuser = createAction('support/GOTO_SUPERUSER')

export const changeLoyaltyStatus = createAction(
  'personal/CHANGE_LOYALTY_STATUS'
)

export const setMessage = createAction('personal/SET_MESSAGE')

export const request =
  ({ clientApi }) =>
    () => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .get('/v2/personal/info/', {
          params: {
            contractor_id: contractorId
          }
        })
        .then(data => fetchSuccess({ contractorId, ...data }))
        .catch(fetchFailure)
    }

const requestCabinet918 =
  ({ clientApi }) =>
    () => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .get('/v2/personal/info/918ok/', {
          params: {
            contractor_id: contractorId
          }
        })
        .then(fetchCabinet918Success)
        .catch(fetchCabinet918Failure)
    }

const handleFetch = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.promise(request({ clientApi }), payload)
  )

const handleFetchSuccess = (state, payload, { clientApi }) => {
  const effects = []
  const response = R.path(['data', 'response'], payload)
  const status = R.propOr({}, 'LOYALTY', response)
  const pointsLoyalty = R.propOr(0, 'BALANCE', status)
  const contractors = R.propOr([], 'CONTRACTORS', response)
  const userId = Number(R.pathOr(0, ['USER', 'ID'], response))
  const isAllowed = R.pathOr(true, ['ACCESS', 'ALLOWED'], response)
  const isSearchAllowed = R.pathOr(false, ['ACCESS', 'SEARCH'], response)
  const contractorFromRequest = R.pathOr(-1, ['contractorId'], payload)
  let contractorId = '-1'

  if (!isAllowed) {
    effects.push(Effects.call(showModal, 'isNotAllowed'))
    effects.push(Effects.call(clearToken))
    effects.push(
      Effects.call(
        setMessage,
        R.pathOr('', ['data', 'warnings', 'not_access'], payload)
      )
    )
  }

  const contractorFromCookie = clientApi.getContractorId(userId)

  if (contractorFromCookie) {
    const existsContractor = R.compose(
      R.lt(0),
      R.length,
      R.filter(item => item.ID === contractorFromCookie)
    )(contractors)
    if (existsContractor) {
      contractorId = contractorFromCookie
      clientApi.setContractorId(contractorId, userId)
    } else {
      clientApi.clearContractorId(userId)
    }
  }
  if (R.equals(contractorId, '-1')) {
    contractorId = R.compose(
      R.path([0, 'ID']),
      R.filter(item => R.equals(true, R.prop('SELECTED', item)))
    )(contractors)
    clientApi.setContractorId(contractorId, userId)
  }

  if (
    contractorId &&
    contractorFromRequest &&
    contractorId !== contractorFromRequest
  ) {
    // еще одно необходимое условие, когда нашли в куках контрагента,
    //  но в запросе он не указан - необходимо перезапросить информацию о пользователе (другие доступы и тд и тп)
    return loop({ ...state }, Effects.call(fetch, {}))
  }

  const support = R.pathOr([], ['COMPANY', 'SUPPORT'], response)
  const managers = R.pathOr([], ['COMPANY', 'MANAGERS'], response)
  const manager = R.compose(
    R.last,
    R.filter(item => R.propEq('POSITION_CODE', 'MANAGER', item))
  )(managers)

  const ok918 = R.pathOr(false, ['ACCESS', 'OK918'], response)
  const companyEmail = R.pathOr(
    '',
    ['COMPANY', 'CUSTOM_SERVICE', 'EMAIL'],
    response
  )

  effects.push(Effects.call(setUserId, userId))

  if (ok918) {
    Effects.call(fetchCabinet918, {})
  }

  const isExistsSuperuser = clientApi.getIsAdminCookie()
  return loop(
    {
      ...state,
      isLoading: false,
      isLoaded: true,
      email: R.pathOr('', ['USER', 'EMAIL'], response),
      title: R.trim(R.pathOr('', ['USER', 'TITLE'], response)),
      role: R.pathOr('USER', ['USER', 'ROLE'], response),
      isUser: R.equals(R.path(['USER', 'ROLE'], response), 'USER'),
      isTempUser: R.equals(R.path(['USER', 'ROLE'], response), 'TEMP_USER'),
      phone: R.pathOr(state.phone, ['COMPANY', 'PHONE'], response),
      address: R.path(['COMPANY', 'ADDRESS'], response),
      name: R.path(['COMPANY', 'TITLE'], response),
      description: R.path(['COMPANY', 'DESCRIPTION'], response),
      siteUrl: R.path(['COMPANY', 'SITE_URL'], response),
      accesses: R.propOr({}, 'ACCESS', response),
      counters: R.pathOr({}, ['COMPANY', 'COUNTER'], response),
      metaTags: R.pathOr([], ['META', 'TAGS'], response),
      metaHead: R.pathOr({}, ['META', 'HEAD'], response),
      isAllowed,
      isSearchAllowed,
      pointsLoyalty,
      onlyRub: R.pathOr(true, ['ACCESS', 'ONLY_RUB'], response),
      feedbackForm: R.pathOr(false, ['ACCESS', 'FEEDBACK_FORMS'], response),
      apiAccess: R.pathOr(false, ['ACCESS', 'EXCHANGE'], response),
      isTableView: R.pathOr(true, ['ACCESS', 'SHOW_CTABLE'], response),
      message: '',
      managers,
      manager,
      support,
      userId,
      isRH: R.pathOr(false, ['COMPANY', 'USE_RH'], response),
      social: {
        vk: R.pathOr('', ['COMPANY', 'VK'], response),
        instagram: R.pathOr('', ['COMPANY', 'INSTAGRAM'], response)
      },
      dateRegister: R.pathOr(
        Math.round(
          new Date().getTime() / MILLISECONDS_IN_SECOND -
            SECONDS_IN_MINUTE * MINUTES_IN_HOUR * HOURS_IN_DAY * DAY_IN_MONTH
        ),
        ['USER', 'DATE_REGISTER'],
        response
      ),
      contractors,
      contractorCode: R.pathOr('', ['ITEM', 'CODE'], response),
      ok918,
      onlyMultiple: R.pathOr(true, ['ACCESS', 'ONLY_MULTIPLICITY'], response),
      costMultiple: R.pathOr(COST_20, ['SALE', 'COST_MULTIPLICITY'], response),
      stores: R.pathOr([], ['SALE', 'STORE'], response),
      isExistsSuperuser,
      adminToken: isExistsSuperuser
        ? clientApi.getAdminTokenFromCookies()
        : null,
      activeContractor: contractorId,
      status,
      companyEmail,
      moderator: R.pathOr({}, ['ACCESS', 'MODERATOR'], response),
      isAuth: propOr(false, 'IS_AUTH', response),
      isShowSchedule: R.pathOr(false, ['ACCESS', 'SHOW_PAYMENTS_PLAN'], response)
    },
    Effects.batch(effects)
  )
}

const handleFetchFailure = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  error: getErrorMessage(payload)
})

const handleSetContractor = (
  state,
  { contractorId, userId = 0 },
  { clientApi }
) => {
  clientApi.setContractorId(contractorId, userId)
  return {
    ...state,
    isChangingContractor: true,
    activeContractor: contractorId
  }
}

export const requestSendReview =
  ({ clientApi }) =>
    data =>
      clientApi
        .post('/v1/personal/review', {
          params: data
        })
        .then(sendReviewSuccess)
        .catch(sendReviewFailure)

const handleSendReview = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isReviewSending: true,
      isReviewSended: false
    },
    Effects.promise(requestSendReview({ clientApi }), payload)
  )

const handleSendReviewSuccess = state => ({
  ...state,
  isReviewSending: false,
  isReviewSended: true
})

const handleSendReviewFailure = (state, payload) => ({
  ...state,
  isReviewSending: false,
  isReviewSended: false,
  error: getErrorMessage(payload)
})

export const requestSearchContractors =
  ({ clientApi }) =>
    ({ q, accessToken = '' }) => {
      const params = {
        q
      }
      if (accessToken) {
        params.accessToken = accessToken
      }
      return clientApi
        .get('/v2/support/auth/', {
          params
        })
        .then(searchContractorsSuccess)
        .catch(searchContractorsFailure)
    }

const handleSearchContractors = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isSearchLoading: true,
      isSearchLoaded: false
    },
    Effects.promise(requestSearchContractors({ clientApi }), payload)
  )

const handleSearchContractorsSuccess = (state, payload) => ({
  ...state,
  isSearchLoading: false,
  isSearchLoaded: true,
  searchedContractors: R.pathOr([], ['data', 'response'], payload)
})

const handleSearchContractorsFailure = state => ({
  ...state,
  isSearchLoading: false,
  isSearchLoaded: false,
  searchedContractors: []
})

const handleSetSearchContractor = (state, payload) => ({
  ...state,
  queryContractor: payload
})

const handleClearSearchContractor = state => ({
  ...state,
  queryContractor: '',
  searchedContractors: []
})

const requestAuthContractors =
  ({ clientApi }) =>
    ({ targetId, accessToken }) => {
      const params = {
        target_id: targetId
      }

      if (accessToken) {
        params.accessToken = accessToken
      }

      return clientApi
        .post('/v2/support/auth/', {
          params
        })
        .then(authContractorSuccess)
        .catch(authContractorFailure)
    }

const handleAuthContractor = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isAuthLoading: true,
      isAuthLoaded: false
    },
    Effects.promise(requestAuthContractors({ clientApi }), payload)
  )

const handleAuthContractorSuccess = (state, payload, { clientApi }) => {
  const clientTokens = R.pathOr({}, ['data', 'response'], payload)
  const adminTokens = clientApi.getTokenFromCookies()
  clientApi.clearToken()
  clientApi.saveToCookies(clientTokens)
  if (getIsSuperuser(state)) {
    clientApi.saveToAdminCookies(adminTokens)
  }
  return loop({ ...state }, Effects.call(hideModal, 'changeContractor'))
}

const handleAuthContractorFailure = state => ({
  ...state
})

const handleGotoSuperuser = (state, payload, { clientApi }) => {
  const adminTokens = clientApi.getAdminTokenFromCookies()
  clientApi.clearToken()
  clientApi.clearAdminToken()
  clientApi.saveToCookies(adminTokens)
  return {
    ...state
  }
}

export const requestBaskets =
  ({ clientApi }) =>
    ({ urlParams, params = {}, type = 'count' }) =>
      clientApi
        .get(
          `/v3/sale/basket/${R.propOr('main', 'catalog', urlParams)}/${type}/`,
          {
            params: {
              contractor_id: clientApi.getContractorId(),
              ...urlParams
            }
          }
        )
        .then(data => fetchBasketsSuccess({ ...data, params, type }))
        .catch(fetchBasketsFailure)

export const handleFetchBaskets = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      // todo hack оставляем preloader только при первой загрузке
      [`isLoading${R.prop('type', payload)}`]:
        !state[`isLoaded${R.prop('type', payload)}`],
      [`isLoaded${R.prop('type', payload)}`]: false
    },
    Effects.promise(requestBaskets({ clientApi }), payload)
  )

export const handleFetchBasketsSuccess = (state, payload) => {
  const effects = []
  const response = R.path(['data', 'response'], payload)
  const type = R.path(['type'], payload)
  const catalog = R.path(['params', 'catalog'], payload)
  effects.push(
    Effects.call(setNav, {
      nav: {
        BASKETS: R.path(['NAV', 'BASKETS'], response)
      }
    })
  )
  effects.push(
    Effects.call(setCarts, {
      carts: R.path(['NAV', 'BASKETS'], response)
    })
  )
  // todo с обновленными пакетами - не работает, выяснить почему
  // effects.push(Effects.call(fetchCartsContractorSuccess, ...payload))
  if (R.equals(type, 'mini')) {
    effects.push(Effects.call(fetchCountSuccessCompare, payload))
    effects.push(Effects.call(fetchCountSuccessFavorite, payload))
    if (R.equals(catalog, 'loyalty')) {
      effects.push(
        Effects.call(fetchProductListSuccess, {
          data: payload,
          ...R.prop('params', payload)
        })
      )
    }
  }
  return loop(
    {
      ...state,
      [`isLoading${type}`]: false,
      [`isLoaded${type}`]: true
    },
    Effects.batch(effects)
  )
}

export const handleSetItemsLoyalty = (state, payload) => ({
  ...state,
  basketProductsLoyalty: payload
})

export const handleFetchBasketsFailure = (state, payload) => ({
  ...state,
  [`isLoading${R.prop('type', payload)}`]: false,
  [`isLoaded${R.prop('type', payload)}`]: false,
  errorBaskets: R.prop('data', payload)
})

export const handleChangeLoyaltyStatus = (state, { balance, isMember }) => ({
  ...state,
  status: {
    MEMBER: isMember,
    BALANCE: balance
  },
  pointsLoyalty: balance
})

export const handleSetMessage = (state, message) => ({
  ...state,
  message
})

const handleFetchCabinet918 = (state, payload, { clientApi }) =>
  loop(
    {
      ...state
    },
    Effects.promise(requestCabinet918({ clientApi }), payload)
  )

export const handleFetchCabinet918Success = (state, payload) => ({
  ...state,
  linkCabinet918: R.pathOr(
    DOMAIN918,
    ['data', 'response', 'LK_DOMAIN'],
    payload
  )
})

export const handleFetchCabinet918Failure = state => ({
  ...state,
  linkCabinet918: null
})

const reducer = createReducer(on => {
  on(fetch, handleFetch)
  on(fetchSuccess, handleFetchSuccess)
  on(fetchFailure, handleFetchFailure)
  on(sendReview, handleSendReview)
  on(sendReviewSuccess, handleSendReviewSuccess)
  on(sendReviewFailure, handleSendReviewFailure)
  on(setContractor, handleSetContractor)
  on(setSearchContractor, handleSetSearchContractor)
  on(clearSearchContractor, handleClearSearchContractor)
  on(searchContractors, handleSearchContractors)
  on(searchContractorsSuccess, handleSearchContractorsSuccess)
  on(searchContractorsFailure, handleSearchContractorsFailure)
  on(authContractor, handleAuthContractor)
  on(authContractorSuccess, handleAuthContractorSuccess)
  on(authContractorFailure, handleAuthContractorFailure)
  on(gotoSuperuser, handleGotoSuperuser)
  on(fetchBaskets, handleFetchBaskets)
  on(fetchBasketsSuccess, handleFetchBasketsSuccess)
  on(fetchBasketsFailure, handleFetchBasketsFailure)
  on(changeLoyaltyStatus, handleChangeLoyaltyStatus)
  on(setMessage, handleSetMessage)
  on(fetchCabinet918, handleFetchCabinet918)
  on(fetchCabinet918Success, handleFetchCabinet918Success)
  on(fetchCabinet918Failure, handleFetchCabinet918Failure)
}, initialState)

export default reducer
