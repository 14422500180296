import React, { useEffect } from 'react'

import { func, object, string } from 'prop-types'
import { prop } from 'ramda'
import { connect } from 'react-redux'

import config from 'config'
import Modal from 'containers/Modal'
import { showModal } from 'redux/modules/modal'
import { currentContractorGuidSelector } from 'redux/modules/personal'
import Title from 'UI/Title'

import s from './FormIo.scss'
import Form from './FormWrapper'
import Product from './Product'

const ModalForm = ({ contractorGuid, product, onShowModal, onClose }) => {
  const onReady = () => formInstance => {
    const instanceProductId = formInstance.getComponent('product_id')
    const instanceProductGuid = formInstance.getComponent('product_guid')
    const contractor = formInstance.getComponent('contractor')
    instanceProductId.setValue(prop('ID', product))
    instanceProductGuid.setValue(prop('XML_ID', product))
    contractor.setValue(contractorGuid)
  }

  useEffect(() => {
    onShowModal('cheaper')
  }, [])

  return (
    <Modal id='cheaper'
      onClose={onClose}>
      <div className={s.container}>
        <Title>Нашли дешевле?</Title>
        <Product product={product} />
        <Form 
          src={`${config.formIo.host}/foundcheaper`}
          formReady={onReady()}
          onClose={onClose}
        />
      </div>
    </Modal>
  )
}

ModalForm.propTypes = {
  product: object,
  onShowModal: func,
  onClose: func,
  contractorGuid: string
}

export default connect(
  ({ personal }) => ({
    contractorGuid: currentContractorGuidSelector(personal)
  }),
  {
    onShowModal: showModal
  }
)(ModalForm)
