import React, { useCallback, useState } from 'react'

import cx from 'classnames'
import { oneOf } from 'prop-types'
import { useSelector } from 'react-redux'

import { isAuthSelector } from 'redux/modules/token'

import s from './FormIo.scss'
import ModalForm from './SecurityModal'

const Security = ({color = 'white'}) => {
  const [isOpen, setIsOpen] = useState(false)

  const isAuth = useSelector(({ token }) => isAuthSelector(token))

  const onOpen = useCallback(() => setIsOpen(true), [])

  const onClose = useCallback(() => setIsOpen(false), [])

  if (!isAuth) {
    return null
  }

  // Эта форма для всех авторизованныхз, вне зависимости от конфига

  return (
    <>
      <button type='button'
        className={cx(s.feedbackButton, {
          [s[`feedbackButton_color_${color}`]]: !!color
        })}
        onClick={onOpen}>
        Служба безопасности
      </button>
      {isOpen && <ModalForm onClose={onClose} />}
    </>
  )
}

Security.propTypes = {
  color: oneOf(['white', 'black'])
}

export default Security
