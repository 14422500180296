/* eslint-disable complexity */
import React, { memo, useState } from 'react'

import { compose, propOr, toLower, pathOr } from 'ramda'
import { useSelector } from 'react-redux'

import HeaderMenu918 from 'components/Header/HeaderMenu918'
import Icon from 'components/Icon'
import Link from 'components/Link'
import Picture from 'components/Picture'
import config from 'config'
import { Security } from 'containers/FormIo'
import HeaderCompany from 'containers/HeaderCompany'
import HeaderDiscounts from 'containers/HeaderDiscounts'
import HeaderLoyalty from 'containers/HeaderLoyalty'
import HeaderPhone from 'containers/HeaderPhone'
import {
  discountsAccessSelector,
  getAddress,
  getDescription,
  getKancobozId,
  getName,
  getPhone,
  isPartnerSelector
} from 'redux/modules/personal'
import { isAuthSelector } from 'redux/modules/token'
import Text from 'UI/Text'
import Title from 'UI/Title'

import { ABOUT_LINKS, FAQ_LINKS } from './constants'
import s from './TabBar.scss'

const SettingsContent = () => {
  const isAuth = useSelector(({ token }) => isAuthSelector(token))
  const name = useSelector(({ personal }) => getName(personal))
  const description = useSelector(({ personal }) => getDescription(personal))
  const phone = useSelector(({ personal }) => getPhone(personal))
  const address = useSelector(({ personal }) => getAddress(personal))
  const kancobozId = useSelector(({ personal }) => getKancobozId(personal))
  const isPartner = useSelector(({ personal }) => isPartnerSelector(personal))
  const marks = useSelector(({ marksMenu }) => propOr([], 'marks', marksMenu))
  const isDiscountsVisible = useSelector(({ personal }) => discountsAccessSelector(personal))

  const [isOpenCatalog, setOpenCatalog] = useState(false)
  const [isOpenAbout, setOpenAbout] = useState(false)
  const [isOpenFaq, setOpenFaq] = useState(false)

  const handleOpenCatalog = () => () => setOpenCatalog(prev => !prev)
  const handleOpenAbout = () => () => setOpenAbout(prev => !prev)
  const handleOpenFaq = () => () => setOpenFaq(prev => !prev)

  return (
    <div className={s.settings}>
      <div className={s.settings__content}>
        <HeaderPhone />
        {isAuth ? (
          <>
            {isDiscountsVisible && <HeaderDiscounts />}
            {isPartner && (
              <HeaderMenu918
                text='Кабинет партнёра'
                iconPath={pathOr('', ['themes', 'default', 'icons', '918ok'], config)}
                iconWrapper='918ok'
                target='_blank'
                isMenuColor
                pureLink
              />
            )}
            <HeaderLoyalty />
          </>
        ) : <HeaderCompany />}
      </div>
      <div className={s.settings__catalog}>
        <span role='presentation'
          onClick={handleOpenCatalog()}>
          <div className={s.settings__head}>
            <Title size={13}
              level={3}>
              Каталоги
            </Title>
            <Icon
              icon='down'
              size='small'
              className={isOpenCatalog && s.settings__icon_rotate}
            />
          </div>
        </span>
        {isOpenCatalog && (
          <ul className={s.settings__list}>
            <li>
              <Link to='/catalog'>Каталог товаров</Link>
            </li>
            <li>
              <Link to='/brand'>Бренды</Link>
            </li>
            {marks.map(item => {
              const code = compose(toLower, propOr('', 'CODE'))(item)
              return (
                <li key={propOr('', 'CODE', item)}>
                  <Link to={`/catalog/mark/${code}`}>
                    {propOr('', 'TITLE', item)}
                  </Link>
                </li>
              )
            })}
          </ul>
        )}
      </div>
      <div className={s.settings__about}>
        <span role='presentation'
          onClick={handleOpenAbout()}>
          <div className={s.settings__head}>
            <Title size={13}
              level={3}>
              О компании
            </Title>
            <Icon
              icon='down'
              size='small'
              className={isOpenAbout && s.settings__icon_rotate}
            />
          </div>
        </span>
        {isOpenAbout && (
          <ul className={s.settings__list}>
            {ABOUT_LINKS.map(({ title, link }) => (
              <li key={link}
                className={s.settings__link}>
                <Link to={link}>{title}</Link>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className={s.settings__faq}>
        <span role='presentation'
          onClick={handleOpenFaq()}>
          <div className={s.settings__head}>
            <Title size={13}
              level={3}>
              Помощь
            </Title>
            <Icon
              icon='down'
              size='small'
              className={isOpenFaq && s.settings__icon_rotate}
            />
          </div>
        </span>
        {isOpenFaq && (
          <ul className={s.settings__list}>
            {FAQ_LINKS.map(({ title, link }) => (
              <li key={link}
                className={s.settings__link}>
                <Link to={link}>{title}</Link>
              </li>
            ))}
            <li className={s.settings__link}>
              <Security color='black' />
            </li>
          </ul>
        )}
      </div>
      <ul className={s.settings__contacts}>
        <li>
          <Title size={13}
            level={3}>
            {name}
          </Title>
          <Text>{description}</Text>
        </li>
        <li>
          <Title size={13}
            level={3}>
            <a href={`tel:${phone}`}>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: phone }}
                itemProp='telephone'
              />
            </a>
          </Title>
          <Text>{address}</Text>
        </li>
        <li>
          {kancobozId && (
            <a
              href={`https://kanzoboz.ru/?rate=${kancobozId}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Picture
                backgroundSize='contain'
                width={88}
                height={31}
                src={`https://rating.kanzoboz.ru/?id=${kancobozId}`}
                alt='Рейтинг канцелярских компаний'
              />
            </a>
          )}
        </li>
      </ul>
    </div>
  )
}

export default memo(SettingsContent)
