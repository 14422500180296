/* eslint-disable complexity */
import React, {
  FC,
  memo,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'

import cx from 'classnames'
import { compose, find, propEq, propOr } from 'ramda'
import { useClickAway, useKeyPressEvent } from 'react-use'

import Icon from 'components/Icon'
import { display } from 'decorators/device'
import Menu from 'UI/Select/Menu'
import MenuMobile from 'UI/Select/MenuMobile'
import { ISelect, ISelectParams } from 'UI/Select/types'

import s from './Select.scss'

const Select: FC<ISelect> = ({
  color = 'transparent',
  isDisabled = false,
  isMobile,
  onClose,
  onSelect,
  options = [],
  placeholder = '',
  selectedValue = ''
}) => {
  const selected: string = compose(
    propOr('', 'title'),
    find(propEq('value', selectedValue))
  )(options) as ReturnType<() => string>
  const [isOpen, setIsOpen] = useState(false)
  const root = useRef(null)
  const selectedRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement>(null)

  const handleAway = () => {
    if (onClose) onClose()
    setIsOpen(false)
  }

  const handleClick = useCallback(() => setIsOpen(prev => !prev), [])

  const handleSelect = useCallback(({ clickedValue, isSelected }: ISelectParams) => {
    setIsOpen(false)
    if (isSelected) return undefined
    onSelect(clickedValue)
    return undefined
  }, [onSelect])

  useClickAway(root, handleAway, ['click'])
  useKeyPressEvent('Escape', handleAway)

  useEffect(() => {
    if (isDisabled) return undefined
    const element = selectedRef.current
    if (!element) return undefined

    const handlePressEnter = (e: KeyboardEvent) => {
      if (document.activeElement === element && e.key === 'Enter')
        setIsOpen(prev => !prev)
    }

    element.addEventListener('keydown', handlePressEnter)
    return () => {
      element.removeEventListener('keydown', handlePressEnter)
    }
  }, [isDisabled])

  return (
    <div ref={root}
      className={s.root}>
      <div
        className={cx(s.root__content, {
          [s[`root__content_color_${color}`]]: !!color,
          [s.root__content_disabled]: isDisabled
        })}
        aria-hidden
        onClick={!isDisabled ? handleClick : undefined}
        ref={selectedRef}
        role='button'
      >
        <div className={s.root__text}>{selected || placeholder}</div>
        <Icon
          className={cx(s.root__icon, {
            [s.root__icon_open]: isOpen
          })}
          icon='down'
          size='small'
        />
      </div>
      {isOpen && (
        isMobile
          ? <MenuMobile
            handleSelect={handleSelect}
            options={options}
            selectedValue={selectedValue}
          />
          : <Menu
            handleSelect={handleSelect}
            options={options}
            selectedValue={selectedValue}
          />
      )}
    </div>
  )
}

export default compose(display, memo)(Select) as FC<ISelect>
