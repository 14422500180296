/* eslint-disable complexity */
import {
  compose,
  equals,
  find,
  isEmpty,
  length,
  map,
  not,
  or,
  pathOr,
  pick,
  pipe,
  prepend,
  prop,
  propEq,
  propOr,
  toLower,
  values
} from 'ramda'
import { createSelector } from 'reselect'

import renameKeys from 'helpers/objectModify'
import { snakeToCamel } from 'helpers/toCamelCase'
import moment from 'utils/moment'

const baseFormatParams = {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0
}

const rubFormat = new Intl.NumberFormat('ru-RU', baseFormatParams)

const rubFormatCurrency = new Intl.NumberFormat('ru-RU', {
  ...baseFormatParams,
  currency: 'rub',
  style: 'currency'
})

export const loadingState = createSelector(
  state => state,
  state => propOr(false, 'isLoading', state))

const navSelector = createSelector(
  state => state,
  state => propOr({}, 'nav', state)
)

export const sortSelector = createSelector(
  navSelector,
  state => propOr([], 'SORT', state)
)

export const sortPrepareSelector = createSelector(
  sortSelector,
  state => map(item => ({
    value: `${propOr('', 'SORT', item)},${propOr('', 'ORDER', item)}`,
    title: propOr('', 'TITLE', item)
  }))(state)
)

export const isLoadingSelector = createSelector(
  state => state,
  state => propOr(false, 'isLoading', state)
)

export const isLoadedSelector = createSelector(
  state => state,
  state => propOr(false, 'isLoaded', state)
)

export const notFoundState = createSelector(
  state => state,
  state => propOr({ state: false }, 'notFound', state)
)

export const countSelector = createSelector(
  state => state,
  state => prop('count', state)
)

export const invoicesItemsSelector = createSelector(
  state => state,
  ({ items = [], entities = {} }) =>
    map(item => {
      const addressId = pathOr('', ['DELIVERY', 'ADDRESS'], item)
      let bills = propOr([], 'BILL', item)
      const billsCount = length(bills)
      const dateFormat = 'DD.MM.YYYY HH:mm'

      bills = isEmpty(bills)
        ? []
        : map(bill => {
          const baseCount = propOr(0, 'INITIAL_COUNT', bill)
          const baseSum = Number(propOr(0, 'INITIAL_SUM', bill))
          const count = propOr(0, 'COUNT', bill)
          const sum = Number(propOr(0, 'SUM', bill))
          const equalsCount = equals(baseCount, count)
          const equalsSum = equals(baseSum, sum)
          return {
            document: propOr({}, 'DOCUMENT', bill),
            baseCount,
            baseSum: rubFormatCurrency.format(baseSum),
            bookmark: propOr(false, 'BOOKMARK', bill),
            count,
            date: moment(propOr(null, 'DATE', bill), 'X').format(dateFormat),
            equalsCount,
            equalsSum,
            id: prop('ID', bill),
            num: propOr('', 'NUM', bill),
            sum: rubFormatCurrency.format(sum),
            showNotice: or(not(equalsCount), not(equalsSum))
          }
        })(bills)
      const baseCount = propOr('', 'INITIAL_COUNT', item)
      const baseSum = rubFormat.format(Number(propOr(0, 'INITIAL_SUM', item)))
      const contractorId = propOr('', 'CONTRACTOR', item)
      const count = propOr('', 'CNT', item)
      const date = prop('DATE', item)
      const dateDelivery = pathOr(null, ['DELIVERY', 'DATE'], item)
      const deliveryTypeId = pathOr('', ['DELIVERY', 'TYPE'], item)
      const expeditor = pathOr({}, ['DELIVERY', 'EXPEDITOR'], item)
      const statusChain = pathOr('', ['STATUS_CHAIN', 'CHAIN'], item)
      const statusGroup = pathOr('', ['STATUS_CHAIN', 'GROUP'], item)
      const statusName = pipe(
        pathOr([], ['STATUS_CHAIN', statusChain]),
        find(propEq('CODE', statusGroup)),
        propOr('', 'NAME')
      )(entities)
      const storeId = propOr('', 'STORE', item)
      const sum = rubFormat.format(Number(propOr(0, 'SUM', item)))
      const userId = propOr('', 'USER', item)
      const vat = Number(propOr(0, 'VAT', item))

      const equalsCount = equals(baseCount, count)
      const equalsSum = equals(baseSum, sum)

      return {
        actions: propOr([], 'ACTIONS', item),
        address: pathOr('', ['ADDRESS', addressId, 'ADDRESS'], entities),
        baseCount: `${baseCount} поз.`,
        baseSum,
        bills,
        billsCount,
        bookmark: propOr(false, 'BOOKMARK', item),
        contractorName: pathOr(
          '',
          ['CONTRACTOR', contractorId, 'TITLE'],
          entities
        ),
        count: `${count} поз.`,
        date: date ? moment(date, 'X').format(dateFormat) : '',
        dateDelivery: dateDelivery
          ? moment(dateDelivery, 'X').format('DD.MM.YYYY')
          : 'Определяется',
        document: propOr({}, 'DOCUMENT', item),
        deliveryType: pathOr(
          '',
          ['DELIVERY', deliveryTypeId, 'NAME'],
          entities
        ),
        equalsCount,
        equalsSum,
        expeditorName: propOr('', 'NAME', expeditor),
        expeditorPhone: propOr('', 'PHONE', expeditor),
        groupId: propOr(null, 'GROUP', item),
        id: prop('ID', item),
        num: propOr('', 'NUM', item),
        numUpd: propOr('', 'NUM_UPD', item),
        shipmentList: compose(
          map(renameKeys(toLower)),
          map(pick(['NUM', 'ID'])),
          propOr([], 'SHIPMENT')
        )(item),
        statusName,
        storeName: pathOr('', ['STORE', storeId, 'NAME'], entities),
        paymentType: equals(
          'loyalty',
          pathOr('', ['PAYMENT_TYPE', prop('PAYMENT_TYPE', item), 'CODE'], entities)
        ) ? 'Б' : '₽',
        showToolTip: Boolean(dateDelivery),
        sum,
        userName: pathOr({}, ['USER', userId], entities),
        vat: rubFormat.format(vat)
      }
    })(items)
)

// тип списка
export const invoicesListTypeSelector = createSelector(
  state => state,
  ({ params }) => propOr('', 'listType')(params)
)

// селектор фильтров
export const filtersSelector = createSelector(
  ({invoices}) => invoices,
  ({type}) => type,
  ({filter}, type) => {
    const aggregation = propOr({}, 'aggregation', filter)
    const entities = propOr({}, 'entities', filter)

    return compose(
      values,
      prepend({ value: String(), title: 'Все' }),
      map(item => renameKeys(snakeToCamel)({...item, value: propOr('', 'id', item)})),
      map(renameKeys(toLower)),
      map(id => pathOr({}, [type, id], entities)),
      propOr([], type)
    )(aggregation)
  }
)

export const defaultTabsSelector = createSelector(
  state => state,
  state => prop('defaultTabs')(state)
)