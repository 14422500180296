/* eslint-disable complexity */
import {
  pathOr,
  propOr,
  join,
  prop,
  keys,
  isEmpty,
  over,
  lensPath
} from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { initialize } from 'redux-form'
import { loop, Effects } from 'redux-loop'

const initialState = {
  isSent: false,
  isSending: false,
  isLoading: false,
  isLoaded: false,
  description: '',
  formFields: [],
  errors: {},
  showError: false
}
// eslint-disable-next-line quote-props
export const fieldsDefined = {
  Имя: 'title',
  Телефон: 'phone',
  Регион: 'address',
  Email: 'email'
}
export const fetch = createAction('feedbackForm/FETCH')
export const fetchSuccess = createAction('feedbackForm/FETCH_SUCCESS')
export const fetchFailure = createAction('feedbackForm/FETCH_FAILURE')
export const saveData = createAction('feedbackForm/SAVE_DATA')
export const setFileError = createAction('feedbackForm/FILE_ERROR')
export const setParam = createAction('feedbackForm/SET_PARAM')

const request =
  ({ clientApi }) =>
    personal =>
      clientApi
        .get('/loyall/')
        .then(data => fetchSuccess({ data, personal }))
        .catch(fetchFailure)

const handleFetch = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.promise(request({ clientApi }), payload)
  )

const handleSetParam = (state, { name, param }) => ({
  ...state,
  [name]: param
})

const handleSaveData = (state, { ...payload }) => ({
  ...state,
  isSending: false,
  isSent: propOr(false, 'isSent', payload),
  showError: propOr(false, 'showError', payload),
  errors: {
    ...state.errors,
    saveError: propOr({}, 'errors', payload)
  }
})

const handleFetchSuccess = (state, { data, personal }) => {
  const description = pathOr('', ['data', 'description'], data)
  let formFields = pathOr([], ['data', 'fields'], data)
  const fields = {}

  formFields.forEach((item, index) => {
    const required = prop('required', item)
    const fieldName = join('_', ['field', prop('id', item), required])
    const title = prop('title', item)
    if (
      keys(fieldsDefined).indexOf(title) !== -1 &&
      !isEmpty(prop('title', personal))
    ) {
      fields[fieldName] = personal[fieldsDefined[title]]
    } else if (item.type !== '70') {
      fields[fieldName] = ''
    } else {
      fields[fieldName] = 1
      formFields = over(lensPath([index, 'options']), JSON.parse, formFields)
    }
  })
  fields.personal = true
  const effects = []
  effects.push(Effects.call(initialize, 'feedbackForm', fields))

  return loop(
    {
      ...state,
      isLoading: false,
      isLoaded: true,
      description,
      formFields
    },
    Effects.batch(effects)
  )
}

const handleFetchFailure = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  errors: {
    ...state.errors,
    fetchError: payload
  }
})

const handleSetFileError = (state, payload) => ({
  ...state,
  errors: {
    ...state.errors,
    fileError: payload
  }
})

const reducer = createReducer(on => {
  on(fetch, handleFetch)
  on(fetchSuccess, handleFetchSuccess)
  on(fetchFailure, handleFetchFailure)
  on(saveData, handleSaveData)
  on(setFileError, handleSetFileError)
  on(setParam, handleSetParam)
}, initialState)

export default reducer
