/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import {
  number,
  bool,
  string,
  array,
  object,
  func,
  oneOfType
} from 'prop-types'
import {
  pathOr,
  propOr,
  isEmpty,
  find,
  or,
  gt,
  prop,
  findIndex,
  subtract,
  last,
  and,
  equals,
  not,
  lt,
  gte
} from 'ramda'

import ContentMenu from 'components/ContentMenu'
import HeaderContacts from 'components/Header/HeaderContacts'
import HeaderMenu918 from 'components/Header/HeaderMenu918'
import HeaderMenuItem from 'components/Header/HeaderMenuItem'
import HeaderTop from 'components/Header/HeaderTop'
import Icon from 'components/Icon'
import Image from 'components/Image'
import Interactions from 'components/Interactions'
import InvoiceList from 'components/InvoiceList'
import Link from 'components/Link'
import Wrapper from 'components/Wrapper'
import config from 'config'
import { CartError } from 'containers/Cart'
import CartModal from 'containers/Cart/CartModal'
import FormAuth from 'containers/FormAuth'
import HeaderBasket from 'containers/HeaderBasket'
import HeaderCompany from 'containers/HeaderCompany'
import HeaderLoyalty from 'containers/HeaderLoyalty'
import HeaderOffers from 'containers/HeaderOffers'
import HeaderPhone from 'containers/HeaderPhone'
import MarksMenu from 'containers/MarksMenu'
import Modal from 'containers/Modal'
import SearchLine from 'containers/SearchLine'
import Preloader from 'UI/Preloader'
import Spacing from 'UI/Spacing'
import Text from 'UI/Text'
import formatAmount from 'utils/formatAmount'

import styles from './Header.scss'

export default class Header extends PureComponent {
  static propTypes = {
    isUserWithoutPassword: bool,
    isKdClient: bool,
    showModal: func,
    setListening: func,
    isVisible: bool,
    manager: object,
    support: array,
    menuItems: array,
    contractors: array,
    userId: oneOfType([string, number]),
    activeContractor: string,
    message: string,
    favoriteCount: number,
    compareCount: number,
    isBookmark: bool,
    isLoyalty: bool,
    isTempUser: bool,
    isPersonalLoaded: bool,
    catalogIcon: string,
    catalogTitle: string,
    setContractor: func,
    onSignOut: func,
    setCatalogVisible: func,
    buttonCatalogRef: func,
    isExistsSuperuser: bool,
    loyaltyBlock: object,
    links: array,
    personalTitle: string,
    isSuperuser: bool,
    headerVisibility: object,
    theme: string,
    hasAccessRegistration: bool,
    dispatch: func,
    fetchInvoice: func,
    invoice: object,
    cards: array,
    currentCard: object,
    lastGuid: string
  }

  static defaultProps = {
    contractors: [],
    personalTitle: '',
    isSuperuser: false,
    isExistsSuperuser: false,
    isPersonalLoaded: false,
    hasAccessRegistration: true,
    isKdClient: true,
    headerVisibility: {},
    support: [],
    manager: {},
    invoice: {},
    loyaltyBlock: {}
  }

  state = {
    isFixed: false,
    isMiniBasketVisible: false
  }

  handleShowPopup = name => () => {
    const { showModal } = this.props
    return showModal(name)
  }

  handleDelayedMouseEnter = () => {
    this.onShowMiniBasket()
  }

  handleFetchInvoice = () => {
    const { invoice, fetchInvoice, isTempUser } = this.props
    if (!propOr(false, 'isLoaded', invoice) && !isTempUser) {
      fetchInvoice({
        limit: 4,
        offset: 0,
        list_type: 'items',
        isHeader: true
      })
    }
  }

  handleGetThemeIcon = icon => {
    const { theme } = this.props
    return pathOr('', ['themes', theme, 'icons', icon], config)
  }

  handleOpenRegistrationForm = () => {
    const { showModal, hasAccessRegistration } = this.props
    if (!hasAccessRegistration) return
    showModal('registration')
  }

  onHideMiniBasket = () => this.setState({ isMiniBasketVisible: false })

  onShowMiniBasket = () => this.setState({ isMiniBasketVisible: true })

  onBreakpointPassed = isPassed => {
    if (!this.state.isFixed && isPassed) {
      this.onHideMiniBasket()
    }

    this.setState({ isFixed: isPassed })
  }

  renderManagerContent = item => (
    <div key={propOr('', 'ID', item)}
      className={styles.helpManager}>
      <div className={styles.imageManager}>
        <Image
          className='previewPicture'
          src={propOr('', 'PHOTO', item)}
          width={64}
          height={64}
          backgroundSize='contain'
        />
      </div>

      <div className={styles.managerText}>
        <span className={styles.managerTitle}>{propOr('', 'TITLE', item)}</span>

        <span className={styles.dropdownSubtitle}>
          {propOr('', 'POSITION', item)}
        </span>

        <span className={styles.managerText}>{propOr('', 'PHONE', item)}</span>
      </div>
    </div>
  )

  renderHelpContent = () => {
    const {
      manager,
      support,
      headerVisibility: { isManagerVisible, isHelpVisible },
      isTempUser
    } = this.props
    return (
      <>
        {isManagerVisible && !isTempUser && !isEmpty(manager) && (
          <div className={styles.helpManagersWrapper}>
            <div className={styles.dropdownSubtitle}>
              Ваш персональный менеджер
            </div>
            {this.renderManagerContent(manager)}
          </div>
        )}
        {isHelpVisible && !isEmpty(support) && (
          <div className={styles.helpManagersWrapper}>
            <div className={styles.dropdownSubtitle}>Техническая поддержка</div>
            {support.map(this.renderManagerContent)}
          </div>
        )}
        <Link to='/faq'
          color='blue'>
          Больше информации &rarr;
        </Link>
      </>
    )
  }

  renderPersonalCabinet = () => {
    const {
      invoice: { isLoading, list },
      dispatch
    } = this.props

    if (isLoading) {
      return (
        <Preloader active={isLoading} />
      )
    }
    return (
      <div className={styles.invoiceWrapper}>
        <div className={styles.dropdownSubtitle}>Последние заказы</div>
        <InvoiceList items={list}
          dispatch={dispatch}
          isHeader />
        <Link to='/cabinet/invoice'
          color='blue'>
          <Spacing margin='top'
            size='small'>
            Больше информации &rarr;
          </Spacing>
        </Link>
      </div>
    )
  }

  renderGetLoyaltyAccessText = () => (
    <span>
      <Link to='/loyalty/conditions'>
        <span className={styles.getLoyaltyAccessLink}>Примите условия</span>
      </Link>

      <span> для того, чтобы выбирать подарки</span>
    </span>
  )

  renderStatusContent = () => {
    const { cards, currentCard, lastGuid } = this.props
    const {
      currentSku,
      currentSum,
      currentStatusName,
      currentStatusDate,
      currentGuid
    } = currentCard

    const maxSku = propOr(0, 'sku', last(cards))
    const maxSum = propOr(0, 'sum', last(cards))
    const minCompletedCard = findIndex(card =>
      or(gt(prop('progressSku', card), 0), gt(prop('progressSum', card), 0))
    )(cards)
    const nextCard = cards[minCompletedCard]
    const nextStatusName = prop('name', nextCard)
    const nextSku = prop('sku', nextCard)
    const nextSum = prop('sum', nextCard)
    const needSku = subtract(nextSku, currentSku)
    const needSum = subtract(nextSum, currentSum)
    const isMax = and(gte(currentSku, maxSku), gte(currentSum, maxSum))
    const lastGuidCard = find(card => equals(lastGuid, prop('guid', card)))(
      cards
    )
    const canUpgrade =
      Boolean(lastGuid) &&
      not(isMax) &&
      not(equals(lastGuid, currentGuid)) &&
      gt(prop('index', lastGuidCard), 0)

    const iconType = () => {
      switch (currentStatusName) {
        case 'Бронзовый':
          return 'star_bronzovaya'
        case 'Серебряный':
          return 'star_serebryanaya'
        case 'Золотой':
          return 'star_zolotaya'
        case 'Платиновый':
          return 'star_platinovaya'
        case 'VIP':
          return 'star_vip'
        default:
          return 'star_max'
      }
    }

    return (
      <div className={styles.statusContent}>
        <Icon icon={iconType()}
          size='middle' />
        <div>
          {isMax && <Text>«У вас максимальная карта!»</Text>}
          {and(gt(needSku, 0), gt(needSum, 0)) && (
            <Text>
              Наберите {formatAmount(needSum)} ₽ оборота и {needSku} SKU для
              достижения требований карты «{nextStatusName}»
            </Text>
          )}
          {and(lt(needSku, 0), gt(needSum, 0)) && (
            <Text>
              Наберите {formatAmount(needSum)} ₽ оборота для достижения
              требований карты «{nextStatusName}»
            </Text>
          )}
          {and(gt(needSku, 0), lt(needSum, 0)) && (
            <Text>
              Наберите {needSku} SKU для достижения требований карты «
              {nextStatusName}»
            </Text>
          )}
          &nbsp;
          {currentStatusDate && (
            <Text>Текущая карта действительна до «{currentStatusDate}»</Text>
          )}
          &nbsp;
          {canUpgrade && <Text>«Вы можете перейти на новую карту!»</Text>}
          &nbsp;
          <Link to='/loyalty/statuses'
            color='blue'>
              Больше информации &rarr;
          </Link>
        </div>
      </div>
    )
  }

  renderModals = () => {
    const { message, isUserWithoutPassword } = this.props

    return (
      <>

        <Modal title='Запрет доступа'
          id='isNotAllowed'>
          {message}
        </Modal>
        <CartModal />
        {!isUserWithoutPassword && <CartError />}
      </>
    )
  }

  render() {
    const {
      isLoyalty,
      menuItems,
      isTempUser,
      favoriteCount,
      compareCount,
      contractors,
      isSuperuser,
      isExistsSuperuser,
      activeContractor,
      setContractor,
      personalTitle,
      onSignOut,
      setCatalogVisible,
      setListening,
      isVisible,
      catalogIcon,
      catalogTitle,
      isUserWithoutPassword,
      links,
      manager,
      support,
      isBookmark,
      isKdClient,
      isPersonalLoaded,
      buttonCatalogRef,
      userId,
      headerVisibility: {
        canJoin,
        isUserInfoVisible,
        isCashbackVisible,
        isManagerVisible,
        isHistoryVisible,
        isCabinetVisible,
        isHelpVisible,
        isPartnerVisible,
        isWhyWeVisible,
        isLoginFormVisible,
        isRegistrationVisible,
        isMiniBasketVisible,
        isLoyaltyVisible,
        isLoyaltyMember,
        isStatusVisible,
        isRulesVisible,
        isOffersVisible
      },
      loyaltyBlock: { statusCode, statusText }
    } = this.props
    const { isFixed } = this.state

    return (
      <header
        className={styles.header}
        itemScope
        itemType='http://schema.org/WPHeader'
      >
        {isTempUser && (
          <div className={styles.headerTempUser}>
            <Wrapper>
              До заключения договора интернет-магазин работает в демо-режиме,
              отправка заказа заблокирована.
            </Wrapper>
          </div>
        )}
        {isLoyalty && !isLoyaltyMember && isPersonalLoaded && (
          <div className={styles.headerJoinToLoyalty}>
            <Wrapper>{this.renderGetLoyaltyAccessText()}</Wrapper>
          </div>
        )}
        {isUserInfoVisible && (
          <div className={styles.headerTop}>
            <Wrapper>
              <HeaderTop
                isLoyalty={isLoyalty}
                menuItems={menuItems}
                isTempUser={isTempUser}
                favoriteCount={favoriteCount}
                compareCount={compareCount}
                isBookmark={isBookmark}
                contractors={contractors}
                isSuperuser={isSuperuser}
                isExistsSuperuser={isExistsSuperuser}
                activeContractor={activeContractor}
                setContractor={setContractor}
                personalTitle={personalTitle}
                onSignOut={onSignOut}
                showModal={this.handleShowPopup('changeContractor')}
              />
            </Wrapper>
          </div>
        )}

        <div className={styles.headerInfo}>
          <div
            className={cx(styles.fixedSearchBg, {
              [styles.fixedSearchBgLoyalty]: isLoyalty,
              [styles.fixedSearchBgBookmark]: isBookmark,
              [styles.isShow]: isFixed
            })}
          />

          <Wrapper
            className={styles.wrapper}>
            <HeaderContacts
              className={cx(styles.block, {
                [styles.blockFixed]: isFixed
              })}
              setCatalogVisible={setCatalogVisible}
              setListening={setListening}
              isVisible={isVisible}
              catalogIcon={catalogIcon}
              catalogTitle={catalogTitle}
              isBookmark={isBookmark}
              isLoyalty={isLoyalty}
              handleBreakpointPassed={this.onBreakpointPassed}
              buttonCatalogRef={buttonCatalogRef}
            />
            <div className={styles.headerMain}>
              <div className={styles.headerMenuSearchLine}>
                <div className={styles.headerMenu}>
                  {!isLoyalty ? (
                    <HeaderPhone />
                  ) : (
                    <HeaderMenuItem
                      iconPath={this.handleGetThemeIcon('headerCatalog')}
                      iconWrapper='headerCatalog'
                      linkTo='/'
                      text='Основной каталог'
                      isLoyalty={isLoyalty}
                    />
                  )}

                  {isCashbackVisible && (
                    <HeaderMenuItem
                      iconPath={this.handleGetThemeIcon('loyaltyCashback')}
                      iconWrapper='percent'
                      linkTo='/loyalty/morepoints'
                      text='Кешбэк'
                      isLoyalty={isLoyalty}
                      disabled={!isLoyaltyMember}
                    />
                  )}

                  {(isKdClient) &&
                    (isManagerVisible || isHelpVisible) && (
                    <HeaderMenuItem
                      iconPath={this.handleGetThemeIcon('help')}
                      iconWrapper='help'
                      linkTo='/faq'
                      text='Помощь'
                      dropDownClassName={styles.dropdownHelp}
                      renderWhenUserIsNotLogged={!isUserWithoutPassword}
                    >
                      {(!isEmpty(manager) || !isEmpty(support)) &&
                          this.renderHelpContent()}
                    </HeaderMenuItem>
                  )}

                  {isHistoryVisible && (
                    <HeaderMenuItem
                      iconPath={this.handleGetThemeIcon('history')}
                      iconWrapper='history'
                      linkTo='/loyalty/operations'
                      text='История операций'
                      disabled={!isLoyaltyMember}
                      isLoyalty={isLoyalty}
                    />
                  )}

                  {isCabinetVisible && !isLoyalty && (
                    <Interactions onDelayedMouseEnter={this.handleFetchInvoice}>
                      <HeaderMenuItem
                        iconPath={this.handleGetThemeIcon('personalCabinet')}
                        iconWrapper='personalCabinet'
                        text='Личный кабинет'
                        linkTo='/cabinet'
                        dropDownClassName={styles.dropdownCabinet}
                      >
                        {!isTempUser && this.renderPersonalCabinet()}
                      </HeaderMenuItem>
                    </Interactions>
                  )}

                  {isStatusVisible && (
                    <HeaderMenuItem
                      linkTo='/loyalty/statuses'
                      iconPath={this.handleGetThemeIcon('loyaltyStatus')}
                      iconWrapper='loyaltyStatus'
                      statusCode={statusCode}
                      text={canJoin ? 'Карта' : statusText}
                      disabled={!isLoyaltyMember}
                      isLoyalty={isLoyalty}
                    >
                      {isLoyaltyMember && this.renderStatusContent()}
                    </HeaderMenuItem>
                  )}

                  {isLoyalty && (
                    <HeaderMenuItem
                      linkTo='/loyalty/how_it_works'
                      iconPath={this.handleGetThemeIcon('iconGear')}
                      iconWrapper='iconGear'
                      text='Как это работает'
                      isLoyalty={isLoyalty}
                    />
                  )}
                  {isPartnerVisible && (
                    <HeaderMenu918
                      text='Кабинет партнёра'
                      iconPath={this.handleGetThemeIcon('918ok')}
                      iconWrapper='918ok'
                      target='_blank'
                      isMenuColor
                      pureLink
                    />
                  )}

                  {!isUserWithoutPassword &&
                    isOffersVisible &&
                    !isLoyalty &&
                    userId && (
                    <HeaderOffers
                      iconPath={this.handleGetThemeIcon('offers')}
                    />
                  )}

                  {isWhyWeVisible && <HeaderCompany />}

                  {!isLoyalty &&
                    isLoyaltyVisible &&
                    !isRulesVisible &&
                    <HeaderLoyalty />}

                  {isLoginFormVisible && (
                    <div className={styles.headerMenuLoginForm}>
                      <FormAuth
                        mode='headerAuth'
                      />
                    </div>
                  )}

                  {isRegistrationVisible && (
                    <div
                      className={styles.menuRegistrationSmall} // убрать стили вместе с актуальными событиями
                      onClick={this.handleOpenRegistrationForm}
                      role='presentation'
                    >
                      <HeaderMenuItem
                        iconPath={this.handleGetThemeIcon('registration')}
                        className={styles.headerMenuRegistration}
                        iconWrapper='registration'
                        text='Регистрация'
                        renderPopup={false}
                      />
                    </div>
                  )}

                  {!isUserWithoutPassword &&
                    isMiniBasketVisible &&
                    !isRulesVisible &&
                    userId && (
                    <HeaderBasket
                      onDelayedMouseEnter={this.handleDelayedMouseEnter}
                      // eslint-disable-next-line react/jsx-handler-names
                      onHideMiniBasket={this.onHideMiniBasket}
                      onGetThemeIcon={this.handleGetThemeIcon}
                      isFixed={this.state.isFixed}
                      isMiniBasketVisible={this.state.isMiniBasketVisible}
                    />
                  )}

                  {isLoyalty && isRulesVisible && (
                    <HeaderMenuItem
                      className={styles.getLoyaltyAccess}
                      text={this.renderGetLoyaltyAccessText()}
                      isLoyalty={isLoyalty}
                    />
                  )}
                </div>
                <div
                  className={cx(styles.searchLine, {
                    [styles.searchLineFixed]: isFixed,
                    [styles.searchLineShort]: isFixed && !isUserWithoutPassword
                  })}
                >
                  <SearchLine />
                </div>
              </div>

              {isLoyalty && <ContentMenu items={links} />}

              <MarksMenu />
            </div>
            {this.renderModals()}
          </Wrapper>
        </div>
      </header>
    )
  }
}
