/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import debounce from 'lodash.debounce'
import { func, bool, string, number, object } from 'prop-types'
import {
  prop,
  propOr,
  path,
  pathOr,
  contains,
  compose,
  merge,
  pick,
  toLower
} from 'ramda'
import { Link as RouterLink } from 'react-router-dom'

import Icon from 'components/Icon'
import Link from 'components/Link'
import OrderButtonLoyalty from 'components/OrderButtonLoyalty'
import Picture from 'components/Picture'
import PointsValue from 'components/PointsValue'
import PopupImage from 'components/PopupImage'
import ProductActions from 'components/ProductActions'
import ProductCode from 'components/ProductCode'
import ProductOneActions from 'components/ProductPage/ProductOneActions'
import ProductPrices from 'components/ProductPrices'
import TextTruncate from 'components/TextTruncate'
import config from 'config'
import DisableBodyScroll from 'containers/DisableBodyScroll'
import Labels from 'containers/Labels'
import basket from 'decorators/basket'
import product from 'decorators/product'
import Checkbox from 'UI/Checkbox'
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter'

import styles from './ProductTable.scss'
import Cap from '../Cap'

const DEBOUNCE_INPUT = 1500

@basket
@product
export default class ProductTable extends PureComponent {
  static propTypes = {
    descriptionStores: object,
    multipleBuy: object,
    isUpdating: object,
    removeFromFavorite: func,
    removeFromCompare: func,
    onItemCountChange: func,
    onItemSelect: func,
    onChangeByAnalog: func,
    onChangeInBasket: func,
    setProductModal: func,
    setItemCount: func,
    showModal: func,
    setAnalogsModal: func,
    onChangeOrder: func,
    setActiveStore: func,
    setForPoints: func,
    isUserWithoutPassword: bool,
    withActions: bool,
    checked: bool,
    inFavorite: bool,
    inCompare: bool,
    selectable: bool,
    isBookmark: bool,
    isLoyalty: bool,
    isBasket: bool,
    isLoyaltyBasket: bool,
    isQuickView: bool,
    isMultiple: bool,
    isNotMultiple: bool,
    isBuyNotMultiple: bool,
    forPoints: bool,
    isForPoints: bool,
    isForPointsBasket: bool,
    amount: number,
    itemsCount: number,
    delay: number,
    bookmark: number,
    item: object,
    basket: number,
    step: number,
    remain: number,
    min: number,
    minInit: number,
    stepInit: number,
    id: string,
    groupType: string,
    originalProduct: string,
    activeStore: string,
    basketStore: string
  }

  static defaultProps = {
    removeFromFavorite: () => {},
    removeFromCompare: () => {},
    onItemCountChange: () => {},
    onItemSelect: () => {},
    onChangeByAnalog: () => {},
    onChangeInBasket: () => {},
    setProductModal: () => {},
    setItemCount: () => {},
    showModal: () => {},
    setAnalogsModal: () => {},
    onChangeOrder: () => {},
    setActiveStore: () => {},
    setForPoints: () => {},
    selectable: true,
    isQuickView: false,
    isMultiple: true,
    isNotMultiple: false,
    isBuyNotMultiple: false,
    isUserWithoutPassword: false,
    withActions: false,
    checked: false,
    inFavorite: false,
    inCompare: false,
    isBookmark: false,
    isLoyalty: false,
    isBasket: false,
    isLoyaltyBasket: false,
    forPoints: false,
    isForPoints: false,
    isForPointsBasket: false
  }

  state = {
    isImagePopup: false,
    markToRemove: false
  }

  handleRemove = () => {
    this.setState({ markToRemove: true })
    this.props.setItemCount(0)
  }

  handleShowPopup = popup => e => {
    const MIDDLE_BUTTON = 1
    if (e.button === MIDDLE_BUTTON) return
    this.setState({
      [popup]: true
    })
  }

  handleHidePopup = popup => () => {
    this.setState({
      [popup]: false
    })
  }

  handleShowAnalogs = id => () => {
    this.props.setAnalogsModal(id)
  }

  handleShowQuickView = () => {
    const { setProductModal, id, isQuickView } = this.props
    if (isQuickView) {
      setProductModal(id)
    }
  }

  handleImageLinkClick = e => {
    const MAIN_BUTTON = 0
    if (e.button === MAIN_BUTTON) e.preventDefault()
  }

  handleChange = (value, isCorrect) => {
    const amount = parseInt(
      path(['BASKETS', 'MAIN', 0, 'QUANTITY'], this.props.item),
      10
    )
    if (isCorrect && value !== amount) {
      this.setItemAmount(value)
    }
    this.props.onItemCountChange(value)
  }

  handleKeyUp = (event, value, isCorrect) => {
    if (event.key !== 'Enter') {
      return
    }
    this.handleChange(value, isCorrect)
  }

  handleSetStore = store => this.props.setActiveStore({ store })

  setItemAmount = debounce(value => {
    this.props.onChangeInBasket(value)
  }, DEBOUNCE_INPUT)

  renderCheckbox() {
    const { checked, onItemSelect } = this.props
    return (
      <div className={styles.checkboxColumn}>
        <Checkbox
          checked={checked}
          onChange={onItemSelect}
        />
      </div>
    )
  }

  renderImage(item) {
    const { isLoyalty, isBookmark, bookmark, isUserWithoutPassword } =
      this.props
    const outOfAssortment =
      !isUserWithoutPassword && !propOr(true, 'ACTIVE', item)
    const isGrayPreview = isUserWithoutPassword ? false : outOfAssortment

    return (
      <RouterLink
        to={`${isLoyalty ? '/loyalty' : ''}${
          isBookmark
            ? `/bookmark/${bookmark}/catalog/product/`
            : '/catalog/product/'
        }${prop('ID', item)}`}
        onClick={this.handleImageLinkClick}
      >
        <Picture
          backgroundSize='contain'
          imgClassName={cx(styles.tableImage, styles.previewPicture, {
            [styles.previewPicture_grayscale]: isGrayPreview,
            [styles.previewPicture_zoom]: isLoyalty
          })}
          width={120}
          height={120}
          src={prop('PREVIEW_PICTURE_PATH', item)}
          onClick={
            !isLoyalty
              ? this.handleShowQuickView
              : this.handleShowPopup('isImagePopup')
          }
          isLazy={false}
        />
      </RouterLink>
    )
  }

  render() {
    const {
      item,
      multipleBuy,
      groupType,
      selectable,
      isLoyalty,
      isBasket,
      isUpdating,
      isBookmark,
      bookmark,
      isLoyaltyBasket,
      originalProduct,
      isUserWithoutPassword,
      isQuickView,
      isMultiple,
      isNotMultiple,
      isBuyNotMultiple,
      itemsCount,
      activeStore,
      forPoints,
      isForPoints,
      isForPointsBasket,
      setForPoints
    } = this.props
    if (isBasket && this.state.markToRemove) {
      return null
    }

    const price = parseFloat(propOr(0, 'PRICE', item))
    const withIcon = contains(groupType, ['compare', 'favorite'])
    const handleOnCloseMap = {
      favorite: this.props.removeFromFavorite,
      compare: this.props.removeFromCompare
    }
    const article = path(['ARTICLE'], item)
    const basketLoyalty = Number(
      pathOr(0, ['BASKETS', 'LOYALTY', 0, 'QUANTITY'], item)
    )
    const countLoyalty = itemsCount || basketLoyalty || 1
    const totalPrice = countLoyalty * price
    const isUpdatingLoyalty =
      isLoyalty && propOr(false, 'loyaltyBasket', isUpdating)
    const brandId = path(['TM', 'ID'], item)
    const brandName = path(['TM', 'VALUE'], item)
    const inAssortment = propOr(true, 'ACTIVE', item)
    const outOfAssortment = !isUserWithoutPassword && !inAssortment
    const isGrayPreview = isUserWithoutPassword ? false : outOfAssortment
    const analogsInfo = compose(
      merge({ selectable }),
      pick(['ACTIVE', 'ISSET_ANALOGS'])
    )(item)
    const messageType = toLower(pathOr('', ['INFO', 'TYPE'], item))
    return (
      <div
        className={cx(styles.productTable, {
          [styles.basketTable]: isLoyalty && isBasket,
          [styles[`basketTable_${messageType}`]]:
            isLoyalty && isBasket && !!messageType,
          [styles.productTable_loyalty]: isLoyalty,
          [styles.productTable_notSelectable]:
            !selectable || isUserWithoutPassword,
          [styles.productTable_modalAnalogs]: !selectable && !inAssortment
        })}
        itemProp='offers'
        itemScope
        itemType='http://schema.org/AggregateOffer'
      >
        {withIcon && (
          <div
            className={styles.close}
            onClick={handleOnCloseMap[groupType]}
            role='presentation'
          >
            <Icon icon='close'
              className={styles.icon} />
          </div>
        )}

        {!isUserWithoutPassword &&
          selectable &&
          !isLoyaltyBasket &&
          !isLoyalty &&
          this.renderCheckbox()}

        <div
          className={cx(styles.imageColumn, {
            [styles.imageColumn_loyalty]: isLoyalty,
            [styles.imageColumn_loyalty_basket]: isLoyaltyBasket,
            [styles.imageColumn_small]: !selectable
          })}
        >
          {isQuickView && (
            <div
              className={styles.imageHover}
              onClick={this.handleShowQuickView}
              role='presentation'
            >
              <Icon className={styles.imageHoverIcon}
                icon='eye' />
              Просмотр
            </div>
          )}

          {this.renderImage(item)}

          {this.state.isImagePopup && (
            <DisableBodyScroll>
              <PopupImage
                src={prop('DETAIL_PICTURE_PATH', item)}
                onClose={this.handleHidePopup('isImagePopup')}
                className={cx({
                  [styles.popupPicture_grayscale]: isGrayPreview
                })}
              />
            </DisableBodyScroll>
          )}
        </div>

        <div
          className={cx(styles.nameColumn, {
            [styles.nameColumn_loyalty]: isLoyalty,
            [styles.nameColumn_loyalty_basket]: isLoyaltyBasket
          })}
        >
          <div className={styles.nameBlock}
            itemProp='name'>
            <TextTruncate
              component={Link}
              className={styles.name}
              to={`${isLoyalty ? '/loyalty' : ''}${
                isBookmark && bookmark
                  ? `/bookmark/${bookmark}/catalog/product/`
                  : '/catalog/product/'
              }${prop('ID', item)}`}
              itemProp='url'
            >
              {capitalizeFirstLetter(item.NAME)}
            </TextTruncate>
            <div
              className={cx(styles.artGroup, {
                [styles.artGroup_loyalty]: isLoyalty
              })}
            >
              <ProductCode
                name='Код'
                type='code'
                className={styles.code}
                code={prop('CODE_1C', item)}
                isLoyalty={isLoyalty}
              />
              {article && !(isBasket && isLoyalty) && (
                <ProductCode
                  name='Артикул'
                  type='vendor'
                  className={styles.art}
                  code={article}
                  size='small'
                  isLoyalty={isLoyalty}
                />
              )}
            </div>
            {brandId && (
              <div className={styles.brandBlock}
                itemProp='brand'>
                {isLoyalty ? (
                  <div className={styles.brand}>
                    Бренд{' '}
                    <span className={styles.brandTitle}
                      itemProp='name'>
                      {brandName}
                    </span>
                  </div>
                ) : (
                  <span className={styles.brand}>
                    Бренд{' '}
                    <Link to={`/catalog/brand/${brandId}`}
                      color='blue'>
                      {brandName}
                    </Link>
                  </span>
                )}
              </div>
            )}
            {!isLoyalty && (
              <div className={styles.labels}>
                <Labels item={item}
                  type='table' />
              </div>
            )}
          </div>
        </div>
        <div
          className={cx(styles.priceColumn, {
            [styles.priceColumn_small]: isBasket,
            [styles.priceColumn_loyalty_basket]: isLoyaltyBasket
          })}
        >
          {!isBasket && (this.props.withActions || outOfAssortment) && isLoyalty && (
            <ProductOneActions
              id={this.props.id}
              mode='table'
              isRightBlock
            />
          )}
          {!isBasket && (this.props.withActions || outOfAssortment) && !isLoyalty && (
            <div className={styles.orderBlock}>
              <ProductPrices
                className={styles.prices}
                item={item}
                multipleBuy={multipleBuy}
                isLoyalty={isLoyalty}
                isUserWithoutPassword={isUserWithoutPassword}
                onShowAnalogs={this.handleShowAnalogs}
                selectable={selectable}
                isMultiple={isMultiple}
                isNotMultiple={isNotMultiple}
                isBuyNotMultiple={isBuyNotMultiple}
                activeStore={activeStore}
                forPoints={forPoints}
                isForPoints={isForPoints}
                setForPoints={setForPoints}
                amount={this.props.basket || this.props.amount}
              />
              <div className={styles.actions}>
                <ProductActions
                  productId={item.ID}
                  originalProduct={originalProduct}
                  onChangeByAnalog={this.props.onChangeByAnalog}
                  inCompare={this.props.inCompare}
                  inFavorite={this.props.inFavorite}
                  delay={this.props.delay}
                  analogsInfo={analogsInfo}
                  onShowAnalogs={this.handleShowAnalogs}
                  onUpdateGroup={this.props.onChangeInBasket}
                  onChange={this.props.onChangeOrder}
                  isBookmark={isBookmark}
                  bookmark={bookmark}
                  isMultiple={isMultiple}
                  isNotMultiple={isNotMultiple}
                  isBuyNotMultiple={isBuyNotMultiple}
                  isForPoints={isForPoints}
                  isForPointsBasket={isForPointsBasket}
                  groupType='catalog'
                  removeFromCompare={this.props.removeFromCompare}
                  removeFromFavorite={this.props.removeFromFavorite}
                  type={this.props.groupType}
                  onSetStore={this.handleSetStore}
                  activeStore={activeStore}
                  basketStore={this.props.basketStore}
                  item={this.props.item}
                  min={this.props.min}
                  remain={this.props.remain}
                  step={this.props.step}
                  minInit={this.props.minInit}
                  stepInit={this.props.stepInit}
                  amount={this.props.amount}
                  basket={this.props.basket}
                  isUpdating={this.props.isUpdating}
                  descriptionStores={this.props.descriptionStores}
                />
              </div>
            </div>
          )}
          {!isBasket && !(this.props.withActions || outOfAssortment) && (
            <Cap
              showModal={this.props.showModal}
              mode='ProductTable'
              price={parseFloat(pathOr(0, ['PRICE'], item))}
              multiplicity={pathOr(false, ['MULTIPLICITY_HTML'], item)}
            />
          )}
          {isBasket && isLoyalty && (
            <PointsValue
              points={price}
              mode='price'
              capitalize={false}
              coloredTitle={false}
              titleSize={16}
            />
          )}
        </div>

        {isBasket && isLoyalty && (
          <div
            className={cx(styles.basketAmount, {
              [styles.basketAmount_loyalty_basket]: isLoyaltyBasket
            })}
          >
            <div
              className={cx(
                styles.basketOrderButton,
                styles.orderButtonWrapper
              )}
            >
              <OrderButtonLoyalty
                amount={countLoyalty}
                width={243}
                onChange={this.handleChange}
                onKeyUp={this.handleKeyUp}
                step={1}
                min={1}
                max={config.loyaltyOrderMaxLimit}
                mode='Base'
                disabled={isUpdatingLoyalty}
              />
            </div>
          </div>
        )}
        {isBasket && isLoyalty && (
          <div
            className={cx(styles.totalColumn, {
              [styles.totalColumn_loyalty_basket]: isLoyaltyBasket
            })}
          >
            <div className={styles.totalPoints}>
              <PointsValue
                points={totalPrice}
                capitalize={false}
                coloredTitle={false}
                mode='basket'
              />
            </div>
            <button
              type='button'
              className={cx({
                [styles.button]: true,
                [styles.buttonLoyalty]: isLoyalty
              })}
              onClick={this.handleRemove}
            >
              <Icon className={styles.iconClose}
                icon='close' />
            </button>
          </div>
        )}
      </div>
    )
  }
}
