/* eslint-disable complexity */
import React, { useCallback, PureComponent } from 'react'

import cx from 'classnames'
import { func, bool, number, oneOf, string, oneOfType } from 'prop-types'
import { propOr } from 'ramda'
import { connect } from 'react-redux'
import { animateScroll as scroll } from 'react-scroll'

import CurrencyValue from 'components/CurrencyValue'
import { display } from 'decorators/device'
import { modalActions } from 'decorators/modal'
import { getRegistrationAccess } from 'redux/modules/personal'
import Button from 'UI/Button'

import styles from './Cap.scss'

const CapMobile = ({ showModal, price }) => {
  const handleShowAuth = useCallback(() => {
    showModal('auth')
  }, [showModal])

  return (
    <div className={styles.cap}>
      {!!price && (
        <div className={styles.capPrice}>
          <div className={cx(styles.price)}>
            <CurrencyValue price={price}
              className={styles.currencyValue} />
          </div>
        </div>
      )}
      <Button
        size='micro'
        color='transparentBlue'
        onClick={handleShowAuth}
      >
        войдите,
      </Button>
      <span className={styles.hint}>чтобы увидеть цены</span>
    </div>
  )
}

CapMobile.propTypes = {
  showModal: func,
  price: number
}

const CapMobileWrapper = modalActions(CapMobile)

class CapDesktop extends PureComponent {
  static propTypes = {
    showModal: func,
    multiplicity: oneOfType([string, bool]),
    price: number,
    mode: oneOf(['Base', 'ProductCard', 'ProductList', 'ProductTable']),
    hasAccessRegistration: bool
  }

  static defaultProps = {
    hasAccessRegistration: true,
    showModal: () => {},
    mode: 'Base'
  }

  handleGoToAuth = () => {
    scroll.scrollToTop({ duration: 500 })
  }

  handleOpenRegistrationForm = () => this.props.showModal('registration')

  render() {
    const { mode, price, multiplicity, hasAccessRegistration } = this.props
    const authStyle = mode === 'ProductTable' ? { marginLeft: 0 } : undefined
    return (
      <div
        className={cx(styles.cap, {
          [styles[`cap${mode}`]]: mode,
          [styles.withOutPrice]: !price
        })}
      >
        {!!price && (
          <div className={styles.capPrice}>
            <div className={cx(styles.price)}>
              <CurrencyValue price={price}
                className={styles.currencyValue} />
            </div>
            {multiplicity && (
              <div className={cx(styles.partGroup)}>
                <i className={styles.partIcon}>
                  <span />
                  <span />
                  <span />
                </i>
                <span className={styles.batch}>Парт./уп. {multiplicity}</span>
              </div>
            )}
          </div>
        )}
        {!!price && <div className={styles.delimiter} />}
        <span className={styles.text}>
          Чтобы увидеть персональные цены и сделать заказ,
          {mode !== 'ProductList' && <br />}
          <button
            className={styles.link}
            style={authStyle}
            onClick={this.handleGoToAuth}
            type='button'
          >
            авторизуйтесь
          </button>
          {mode === 'ProductCard' && <br />}
          {hasAccessRegistration && (
            <span className={styles.registerText}>
              {' '}
              или пройдите{' '}
              <div
                role='presentation'
                onClick={this.handleOpenRegistrationForm}
                className={cx(styles.link, styles.registerLink)}
              >
                регистрацию
              </div>
            </span>
          )}
        </span>
      </div>
    )
  }
}

const CapDesktopWrapper = connect(({ personal }) => ({
  hasAccessRegistration: getRegistrationAccess(personal)
}))(CapDesktop)

const Cap = ({ isDesktop, ...etc }) => isDesktop ?
  <CapDesktopWrapper {...etc}/>
  : <CapMobileWrapper price={propOr(0, 'price', etc)} />

Cap.propTypes = {
  isDesktop: bool
}

export default display(Cap)
