import { compose, map, pathOr, prop, propOr } from 'ramda'
import { createSelector } from 'reselect'

import { phoneNumberRegex } from 'utils/testRegexp'

const formatPhoneNumber = phoneNumber => {
  if (!phoneNumber) return ''

  const cleaned = phoneNumber.replace(/\D/g, '')
  const match = cleaned.match(phoneNumberRegex)
  if (!match) {
    return phoneNumber
  }
  const country = pathOr('', ['groups', 'country'], match)
  const operator = pathOr('', ['groups', 'operator'], match)
  const userGroup3 = pathOr('', ['groups', 'userGroup3'], match)
  const userGroup2 = pathOr('', ['groups', 'userGroup2'], match)
  const userGroup1 = pathOr('', ['groups', 'userGroup1'], match)
  const code = country === '8' ? '+7' : `+${country}`
  return `${code} (${operator}) ${userGroup3}-${userGroup2}-${userGroup1}`
}

export const getIsLoaded = state => state.isLoaded
export const getIsLoading = state => state.isLoading
export const getPersonal = state => state.personal
export const getStatusConfirm = state => state.statusConfirm
export const getMessage = state => state.message
export const getFormData = createSelector(
  (form, props) => ({ form, props }),
  ({ form, props }) => prop(form, props)
)

const formatEmployee = item => ({
  ...item,
  PHONE: formatPhoneNumber(prop('PHONE')(item))
})

export const getEmployees = createSelector(
  state => state,
  state => compose(map(formatEmployee), propOr([], 'employees'))(state)
)
