import React, { memo } from 'react'

import cx from 'classnames'
import { number } from 'prop-types'
import { divide, map, multiply } from 'ramda'

import Icon from 'components/Icon'

import s from './RatingStar.scss'

const NUMBER_100 = 100

const getProgress = ({ rating, stars }) =>
  multiply(divide(rating, stars), NUMBER_100)

const RatingStar = ({ rating, stars }) => {
  return (
    <div className={s.rating}>
      <div className={s.ratingLine}>
        {map(() => (
          <Icon
            key={Math.random()}
            className={s.ratingLineStar}
            icon='star-status'
            size='middle'
          />
        ))([...Array(stars)])}
      </div>
      <div
        className={cx(s.ratingLine, s.ratingLineCurrent)}
        style={{ width: `${getProgress({ rating, stars })}%` }}
        itemProp='ratingValue'
      >
        {map(() => (
          <Icon
            key={Math.random()}
            className={s.ratingLineStarCurrent}
            icon='star-status'
            size='middle'
          />
        ))([...Array(stars)])}
      </div>
    </div>
  )
}

RatingStar.propTypes = {
  rating: number,
  stars: number
}

export default memo(RatingStar)
