import React, { useEffect } from 'react'

import { func, string } from 'prop-types'
import { connect } from 'react-redux'

import config from 'config'
import Modal from 'containers/Modal'
import { showModal } from 'redux/modules/modal'
import { currentContractorGuidSelector } from 'redux/modules/personal'

import s from './FormIo.scss'
import Form from './FormWrapper'

const ModalForm = ({ contractorGuid, onShowModal, onClose }) => {
  useEffect(() => {
    onShowModal('expansionProducts')
  }, [])

  const onReady = () => formInstance => {
    const contractor = formInstance.getComponent('contractor')
    contractor.setValue(contractorGuid)
  }

  return (
    <Modal id='expansionProducts'
      onClose={onClose}>
      <div className={s.container}>
        <Form onClose={onClose}
          formReady={onReady()}
          src={`${config.formIo.host}/rangeexpansion`}
        />
      </div>
    </Modal>
  )
}

ModalForm.propTypes = {
  onShowModal: func,
  onClose: func,
  contractorGuid: string
}

export default connect(
  ({ personal }) => ({
    contractorGuid: currentContractorGuidSelector(personal)
  }),
  {
    onShowModal: showModal
  }
)(ModalForm)
