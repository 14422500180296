import React, { useCallback, useState } from 'react'

import { object } from 'prop-types'

import Button from 'UI/Button'

import ModalForm from './ProductReviewModal'

const ButtonForm = ({ product }) => {
  const [isOpen, setIsOpen] = useState(false)

  const onOpen = useCallback(() => setIsOpen(true), [])

  const onClose = useCallback(() => setIsOpen(false), [])

  return (
    <>
      <Button
        stretched
        color='main'
        onClick={onOpen}
      >
        Оставить отзыв
      </Button>
      {isOpen && <ModalForm product={product}
        onClose={onClose} />}
    </>
  )
}

ButtonForm.propTypes = {
  product: object
}

export default ButtonForm
