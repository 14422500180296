/* eslint-disable complexity */
import React from 'react'

import cx from 'classnames'
import { bool, func, string, number } from 'prop-types'

import Icon from 'components/Icon'

import s from './ButtonMoreOptions.scss'

const ButtonOptions = ({
  basket = 0,
  inFavorite = false,
  inCompare = false,
  isBookmark = false,
  bookmark = 0,
  activeStore,
  onUpdateGroup = () => {},
  removeFromCompare = () => {},
  isRemoveFromCompare = false,
  isHorizontal = false,
  isFavoriteButton = true,
  isCompareButton = true,
  isBasketButton = true
}) => {
  const onSetOption = (typeUpdate, count) => () => {
    if (typeUpdate === 'compare' && count === 0 && isRemoveFromCompare) {
      return removeFromCompare()
    }
    return onUpdateGroup(count ? 1 : 0, { type: typeUpdate })
  }

  const onDeleteBasket = () => () => {
    if (basket <= 0) {
      return
    }
    onUpdateGroup(
      {
        QUANTITY: 0,
        STORE: activeStore
      },
      { type: 'basket', group: [isBookmark ? 'bookmark' : 'main', bookmark] }
    )
  }

  const inBasket = basket > 0

  return (
    <div
      className={cx(s.options, {
        [s.optionsHorizontal]: isHorizontal
      })}
    >
      {isFavoriteButton && (
        <div
          className={cx(s.option, s.favorite)}
          title={inFavorite ? 'Удалить из избранного' : 'Добавить в избранное'}
        >
          <Icon
            icon={inFavorite ? 'heartFull' : 'heart'}
            onClick={onSetOption('favorite', inFavorite ? 0 : 1)}
            className={cx(s.icon, s.iconFavorite, {
              [s.iconFavoriteActive]: inFavorite
            })}
          />
        </div>
      )}
      {isCompareButton && (
        <div
          className={cx(s.option, s.compare)}
          title={inCompare ? 'Удалить из сравнения' : 'Добавить в сравнение'}
        >
          <Icon
            icon='compare'
            onClick={onSetOption('compare', inCompare ? 0 : 1)}
            className={cx(s.icon, s.iconCompare, {
              [s.iconCompareActive]: inCompare
            })}
          />
        </div>
      )}
      {isBasketButton && (
        <div
          className={cx(s.option, s.basket)}
          title={inBasket ? 'Удалить из корзины' : 'Добавить в корзину'}
        >
          <Icon
            icon='delete'
            onClick={onDeleteBasket()}
            className={cx(s.icon, s.iconBasket, {
              [s.iconBasketActive]: inBasket
            })}
          />
        </div>
      )}
    </div>
  )
}

ButtonOptions.propTypes = {
  onUpdateGroup: func,
  removeFromCompare: func,
  isRemoveFromCompare: bool,
  isHorizontal: bool,
  inCompare: bool,
  inFavorite: bool,
  isBookmark: bool,
  isFavoriteButton: bool,
  isCompareButton: bool,
  isBasketButton: bool,
  bookmark: number,
  basket: number,
  activeStore: string
}

export default ButtonOptions
