import { memo, useCallback, useEffect, useRef } from 'react'

import { oneOfType, node, bool, arrayOf } from 'prop-types'
import { useDispatch } from 'react-redux'

import { setDisabledBodyScroll } from 'redux/modules/settings'

const DisableBodyScroll = ({ children, isNested = false }) => {

  const bodyRef = useRef(document.body)

  const dispatch = useDispatch()

  const scrollbarWidth = 17

  const onSetDisabledBodyScroll = useCallback(
    params => dispatch(setDisabledBodyScroll(params)),
    [dispatch]
  )

  useEffect(() => {
    onSetDisabledBodyScroll(true)
    bodyRef.current.style.overflow = 'hidden'
    bodyRef.current.style.paddingRight = `${scrollbarWidth}px`

    return () => {
      onSetDisabledBodyScroll(false)
      bodyRef.current.style.overflow = 'auto'
      bodyRef.current.style.paddingRight = 0
    }
  }, [isNested, onSetDisabledBodyScroll])

  return children
}

DisableBodyScroll.propTypes = {
  children: oneOfType([node, arrayOf(node)]),
  isNested: bool
}

export default memo(DisableBodyScroll)
