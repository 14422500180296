import React from 'react'

import cx from 'classnames'
import { string, bool, oneOf } from 'prop-types'

import CopyPopup from 'components/CopyPopup'

import s from './ProductCode.scss'

const ProductCode = ({
  className,
  name,
  code,
  type = 'code',
  size = 'default',
  isLoyalty = false
}) => {
  return (
    <span
      className={cx(s.code, {
        [className]: className
      })}
      itemProp='serialNumber'
    >
      {name}:&nbsp;
      <CopyPopup text={`${name} скопирован`}
        value={code}>
        <button
          type='button'
          className={cx(s.codeValue, {
            [s[`codeValue${size}`]]: !!size,
            [s[`codeValue${type}`]]: !!type,
            [s.codeValueLoyalty]: isLoyalty
          })}
          title={code}
        >
          {code}
        </button>
      </CopyPopup>
    </span>
  )
}

ProductCode.propTypes = {
  className: string,
  name: string,
  code: string,
  isLoyalty: bool,
  type: oneOf(['code', 'vendor', 'barcode']),
  size: oneOf(['small', 'default', 'big'])
}

export default ProductCode
