import { connectRouter } from 'connected-react-router'
import { pick } from 'ramda'
import { reducer as reduxAsyncConnect } from 'redux-connect'
import { reducer as formReducer } from 'redux-form'
import { combineReducers } from 'redux-loop'

import api from './api'
import apiAuth from './apiAuth'
import banners from './banners'
import basket from './basket'
import basketAddByCode from './basketAddByCode'
import basketParams from './basketParams'
import bookkeeping from './bookkeeping'
import bookmarks from './bookmarks'
import brands from './brands'
import brandsDetail from './brandsDetail'
import cabinetAnalytics from './cabinetAnalytics'
import carousel from './carousel'
import catalog from './catalog'
import cities from './cities'
import claim from './claim'
import claimEdit from './claimEdit'
import claimImages from './claimImages'
import claimInvoice from './claimInvoice'
import claimInvoices from './claimInvoices'
import claimSearch from './claimSearch'
import compare from './compare'
import content from './content'
import contractors from './contractors'
import device from './device'
import discounts from './discounts'
import downloadModal from './downloadModal'
import employees from './employees'
import events from './events'
import faq from './faq'
import favorite from './favorite'
import feedback from './feedback'
import feedbackForm from './feedbackForm'
import giftsHistory from './giftsHistory'
import gtm from './gtm'
import invoice from './invoice'
import invoiceDetails from './invoiceDetails'
import invoices from './invoices'
import kladr from './kladr'
import loyalty from './loyalty'
import loyaltyOperationHistory from './loyaltyOperationHistory'
import marksMenu from './marksMenu'
import meta from './meta'
import modal from './modal'
import notice from './notice'
import notifications from './notifications'
import offers from './offers'
import offices from './offices'
import order from './order'
import orders from './orders'
import orderSummary from './orderSummary'
import payments from './payments'
import personal, { resetStore } from './personal'
import productList from './productList'
import productListFilter from './productListFilter'
import productListParams from './productListParams'
import products from './products'
import productsFilter from './productsFilter'
import productsParams from './productsParams'
import registration from './registration'
import search from './search'
import sections from './sections'
import sender from './sender'
import settings from './settings'
import share from './share'
import shipment from './shipment'
import socket from './socket'
import storage from './storage'
import suggestions from './suggestions'
import tabs from './tabs'
import theme from './theme'
import token from './token'
import updateData from './updateData'
import userAgent from './userAgent'

const createRootReducer = history =>
  combineReducers({
    api,
    apiAuth,
    banners,
    basket,
    basketParams,
    basketAddByCode,
    bookkeeping,
    bookmarks,
    brands,
    brandsDetail,
    cabinetAnalytics,
    carousel,
    catalog,
    cities,
    compare,
    content,
    contractors,
    claim,
    claimInvoices,
    claimInvoice,
    claimSearch,
    claimImages,
    claimEdit,
    device,
    discounts,
    downloadModal,
    employees,
    events,
    faq,
    favorite,
    feedback,
    feedbackForm,
    form: formReducer,
    giftsHistory,
    gtm,
    invoice,
    invoices,
    invoiceDetails,
    kladr,
    loyalty,
    loyaltyOperationHistory,
    marksMenu,
    meta,
    modal,
    notice,
    notifications,
    offers,
    offices,
    order,
    orderSummary,
    orders,
    payments,
    personal,
    productList,
    productListFilter,
    productListParams,
    products,
    productsParams,
    productsFilter,
    reduxAsyncConnect,
    registration,
    router: connectRouter(history),
    search,
    sections,
    sender,
    settings,
    share,
    shipment,
    socket,
    storage,
    suggestions,
    tabs,
    theme,
    token,
    updateData,
    userAgent
  })

const rootReducer = history => (state, action) => {
  if (action.type === resetStore().type) {
    // eslint-disable-next-line no-param-reassign
    state = pick(['apiAuth', 'userAgent'], state)
  }
  return createRootReducer(history)(state, action)
}

export default rootReducer
