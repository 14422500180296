import React, { useCallback, useState } from 'react'

import Icon from 'components/Icon'
import config from 'config'
import { forDesktop } from 'decorators/device'

import ModalForm from './CallBackModal'
import s from './FormIo.scss'

const CallBack = () => {
  const [isOpen, setIsOpen] = useState(false)

  const onOpen = useCallback(() => setIsOpen(true), [])

  const onClose = useCallback(() => setIsOpen(false), [])

  if (!config.formIo.isActive) {
    return null
  }

  return (
    <>
      <div className={s.callBack}
        onClick={onOpen}
        role='presentation'>
        <Icon icon='callback'
          className={s.callBackIcon} />
      </div>
      {isOpen && <ModalForm onClose={onClose} />}
    </>
  )
}

export default forDesktop(CallBack)
