import React from 'react'

import { object } from 'prop-types'
import { path } from 'ramda'

import Picture from 'components/Picture'

import s from './FormIo.scss'

const Product = ({ product }) => {
  return (
    <div className={s.product}>
      <div
        className={s.productWrapper}
        itemScope
        itemType='http://schema.org/Product'
      >
        <div className={s.productHeader}>Товар</div>
      </div>
      <div className={s.productWrapper}>
        <div className={s.productImage}>
          <Picture
            backgroundSize='contain'
            width={25}
            height={25}
            src={path(['PREVIEW_PICTURE_PATH'], product)}
            imgClassName={s.image}
            isLazy={false}
          />
        </div>
        <div className={s.productTitle}
          itemProp='name'>
          {path(['NAME'], product)}
        </div>
      </div>
    </div>
  )
}

Product.propTypes = {
  product: object
}

export default Product
