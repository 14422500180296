import React, { PureComponent } from 'react'

import { string, bool, array, object, func, number } from 'prop-types'
import { path, pathOr, propOr, prop } from 'ramda'
import { connect } from 'react-redux'
import { inject } from 'react-tunnel-16'

import ProductQuickViewComponent from 'components/ProductQuickView'
import preventRenderWhileLoading from 'decorators/preventRenderWhileLoading'
import { getItemById, getLoadByGroupAndId } from 'redux/modules/productList'
import {
  fetchSpritePhoto,
  productGalleryPhotoSelector,
  productGallerySettingsSelector,
  galleryModalSettingsSelector,
  productGallerySpriteSelector
} from 'redux/modules/products'
import {
  bookmarkSelector,
  isBookmarkSelector,
  isLoyaltySelector
} from 'redux/modules/settings'

const emptyArray = []

@preventRenderWhileLoading()
@connect(
  ({ productList, products, token, settings, router, loyalty }, { id }) => {
    const product = getItemById(id, productList)
    const bookmark = bookmarkSelector(settings)
    const isBookmark = isBookmarkSelector(settings)
    const isLoyalty = isLoyaltySelector(settings)
    const hasLoyaltyAccess = path(['status', 'ACCESS'], loyalty) === '1'
    const properties = path(['itemsPropsById', product.ID], productList)
    const productType = !isLoyalty ? 'product' : 'loyaltyProduct'
    const productLoadedInfo = getLoadByGroupAndId(
      productType,
      product.ID,
      productList
    )
    const galleryItems = productGalleryPhotoSelector(product, productList)
    return {
      product,
      galleryItems,
      gallerySettings: productGallerySettingsSelector({
        ...galleryItems,
        isQuickView: true
      }),
      galleryModalSettings: galleryModalSettingsSelector(galleryItems),
      gallerySpriteData: productGallerySpriteSelector(product, products),
      properties,
      productIsLoaded: propOr(false, 'isLoaded', productLoadedInfo),
      isUserWithoutPassword: token.isUserWithoutPassword,
      isLoyalty,
      isBookmark,
      bookmark,
      links: isLoyalty
        ? pathOr(emptyArray, ['content', 'items'], loyalty)
        : emptyArray,
      hasLoyaltyAccess,
      location: prop('location', router)
    }
  },
  {
    fetchSprite: fetchSpritePhoto
  }
)
@inject(({ helpers: { updateData, clientApi } }) => ({ updateData, clientApi }))
export default class ProductQuickView extends PureComponent {
  static propTypes = {
    id: string,
    location: object,
    product: object,
    properties: object,
    productIsLoaded: bool,
    fetchSprite: func,
    isUserWithoutPassword: bool,
    isLoyalty: bool,
    isBookmark: bool,
    bookmark: number,
    links: array,
    hasLoyaltyAccess: bool,
    galleryItems: object,
    gallerySettings: object,
    galleryModalSettings: object,
    gallerySpriteData: object,
    updateData: object,
    clientApi: object
  }

  static defaultProps = {
    product: {},
    fetchSprite: () => {},
    galleryItems: {},
    gallerySettings: {},
    galleryModalSettings: {},
    gallerySpriteData: {},
    links: []
  }

  componentDidMount() {
    const { updateData, clientApi, id } = this.props
    updateData.set({ productId: id, view: 'productPage', clientApi })
    updateData.update()
  }

  render() {
    const {
      productIsLoaded,
      isUserWithoutPassword,
      product,
      properties,
      isLoyalty,
      isBookmark,
      bookmark,
      links,
      galleryItems,
      gallerySettings,
      galleryModalSettings,
      hasLoyaltyAccess,
      location,
      gallerySpriteData,
      fetchSprite
    } = this.props
    return (
      <ProductQuickViewComponent
        product={product}
        location={location}
        properties={properties}
        galleryItems={galleryItems}
        gallerySettings={gallerySettings}
        galleryModalSettings={galleryModalSettings}
        gallerySpriteData={gallerySpriteData}
        isUserWithoutPassword={isUserWithoutPassword}
        isLoaded={productIsLoaded}
        isLoyalty={isLoyalty}
        isBookmark={isBookmark}
        bookmark={bookmark}
        links={links}
        hasLoyaltyAccess={hasLoyaltyAccess}
        fetchSpritePhoto={fetchSprite}
      />
    )
  }
}
