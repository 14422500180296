import { prop, inc, propOr, groupBy } from 'ramda'
/* eslint-disable complexity */
import { createAction, createReducer } from 'redux-act'
import { Effects, loop } from 'redux-loop'

import { showModal } from 'redux/modules/modal'

export { connectionSelector, notificationPopup } from './selector'

export const tick = createAction('socket/TICK')
export const socketEmit = createAction('socket/SOCKET_EMIT')
export const socketError = createAction('socket/SOCKET_ERROR')
export const socketData = createAction('socket/SOCKET_ON_DATA')
export const socketConnect = createAction('socket/SOCKET_CONNECT')
export const socketDisconnect = createAction('socket/SOCKET_DISCONNECT')
export const socketJoinRooms = createAction('socket/SOCKET_JOIN_ROOMS')
export const socketLeaveRooms = createAction('socket/SOCKET_LEAVE_ROOMS')
export const socketSend = createAction('socket/SOCKET_SEND')
export const setData = createAction('socket/SET_DATA')
export const newEvent = createAction('socket/NEW_EVENT')
export const setUserId = createAction('socket/SET_USER_ID')

const initialState = {
  isConnected: false,
  isAuth: false,
  userId: 0,
  events: [],
  socketId: '',
  helpId: '',
  message: '',
  countTry: 0,
  notificationPush: [],
  notificationPopup: [],
  notificationHello: []
}

const logging = true

const handleTick = state => ({
  ...state
})

const handleSetUserId = (state, payload) => ({
  ...state,
  userId: payload
})

const handleSocketSend = (state, params) => {
  const effects = []
  effects.push(Effects.call(socketEmit, { ...params }))

  if (logging) {
    effects.push(Effects.call(newEvent, params))
  }
  return loop(
    {
      ...state,
      countTry: inc(state.countTry)
    },
    Effects.batch(effects)
  )
}

const handleSocketDisconnect = state =>
  loop(
    {
      ...state,
      socketId: '',
      countTry: inc(state.countTry),
      isConnected: false
    },
    Effects.call(setData, { isConnected: false })
  )

const handleSocketData = (state, payload, { clientApi }) => {
  const type = prop('type', payload)

  const effects = []

  if (type === 'connected') {
    const socketId = propOr('', 'socketId', payload)
    const accessToken = propOr(
      '',
      'accessToken',
      clientApi.getTokenFromCookies()
    )
    effects.push(
      Effects.call(socketEmit, {
        type: 'auth',
        socketId,
        accessToken
      })
    )
  }

  if (type === 'help_success') {
    const helpId = prop('helpId', payload)
    effects.push(Effects.call(setData, { helpId }))
  }

  if (type === 'auth_success') {
    effects.push(Effects.call(setData, { isAuth: true }))
  }

  if (type === 'alert') {
    const groupData = groupBy(prop('type'), prop('data', payload))
    if (prop('push', groupData)) {
      effects.push(Effects.call(setData, { notificationPush: propOr([], 'push', groupData) }))
    }
    if (prop('popUp', groupData)) {
      effects.push(Effects.call(setData, { notificationPopup: propOr([], 'popUp', groupData) }))
      effects.push(Effects.call(showModal, 'notificationPopup'))
    }
    if (prop('hello', groupData)) {
      effects.push(Effects.call(setData, { notificationHello: propOr([], 'hello', groupData) }))
    }
  }

  if (logging) {
    effects.push(Effects.call(newEvent, payload))
  }
  return loop(
    {
      ...state,
      countTry: inc(state.countTry),
      isConnected: true
    },
    Effects.batch(effects)
  )
}

const handleSetData = (state, payload) => ({
  ...state,
  ...payload
})

const handleNewEvent = (state, payload) => ({
  ...state,
  events: [...state.events, payload]
})

const reducer = createReducer(on => {
  on(tick, handleTick)
  on(socketDisconnect, handleSocketDisconnect)
  on(socketData, handleSocketData)
  on(socketSend, handleSocketSend)
  on(setData, handleSetData)
  on(newEvent, handleNewEvent)
  on(setUserId, handleSetUserId)
}, initialState)

export default reducer
