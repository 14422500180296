/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect, useRef, useCallback } from 'react'

import cx from 'classnames'
import { string, node } from 'prop-types'
import { path } from 'ramda'
import { useCopyToClipboard, useClickAway } from 'react-use'

import s from './CopyPopup.scss'

const TIMEOUT = 2000

const CopyPopup = ({ value, text, children }) => {
  const [isHint, setIsHint] = useState(false)

  const [state, copyToClipboard] = useCopyToClipboard()

  const eventListeners = useRef()

  const refElement = useRef()

  const scrollHandler = useCallback(() => {
    setIsHint(false)
  }, [])

  useEffect(() => {
    window.removeEventListener('scroll', eventListeners.current, true)
    eventListeners.current = scrollHandler
    window.addEventListener('scroll', eventListeners.current, true)
  }, [scrollHandler])

  useClickAway(refElement, () => {
    scrollHandler()
  })

  const handleCopy = () => {
    copyToClipboard(value)
    setIsHint(true)
    setTimeout(() => {
      scrollHandler()
    }, TIMEOUT)
  }

  const renderErrorMessage = () => isHint && state.error && (
    <div className={cx(s.hint, s.error)}>{`Ошибка копирования: ${path(
      ['error', 'message'],
      state
    )}`}</div>
  )

  const isSuccess = isHint && !state.error && state.value

  const renderCopyMessage = () => isSuccess && (
    <div className={s.hint}>
      {text || `Скопировано: ${path(['value'], state)}`}
    </div>
  )

  return (
    <div
      ref={refElement}
      className={s.copy}
      onClick={handleCopy}
      role='presentation'
    >
      {children}
      {renderErrorMessage()}
      {renderCopyMessage()}
    </div>
  )
}

CopyPopup.propTypes = {
  value: string,
  text: string,
  children: node
}

export default CopyPopup
