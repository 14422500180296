/* eslint-disable complexity */
import {
  pathOr,
  isEmpty,
  path,
  composeP,
  propOr,
  prop,
  pick,
  is,
  multiply,
  dec,
  findIndex,
  propEq,
  not
} from 'ramda'

import { cabinetFiltersFromQuery, parseFilters } from 'helpers/cabinetConstants'
import { filtersFromQuery } from 'helpers/productListParams'
import { fetchKeys } from 'redux/modules/api'
import {
  fetch as fetchBookmarks,
  getIsLoaded as isBookmarkLoaded
} from 'redux/modules/bookmarks'
import {
  fetchBrands,
  fetchManufactures,
  getIsLoadedBrandsSelector as isLoadedBrandsSection,
  isLoadedManufacturesSelector
} from 'redux/modules/brands'
import { fetch as fetchAnalytics } from 'redux/modules/cabinetAnalytics'
import { fetch as fetchCompare } from 'redux/modules/compare'
import {
  getIsLoaded as isContentLoaded,
  fetch as fetchContentOld,
  fetchContent,
  fetchContents,
  fetchContentMenu
} from 'redux/modules/content'
import {
  getIsLoaded as isContractorsLoaded,
  getIsLoadedFullAddress,
  fetch as fetchContractors,
  fetchAddress,
  fetchFullAddress
} from 'redux/modules/contractors'
import {
  fetch as fetchDiscounts,
  isLoadedDiscounts
} from 'redux/modules/discounts'
import {
  fetchEmployees,
  fetchPersonal,
  getIsLoaded as isEmployeesLoaded
} from 'redux/modules/employees'
import {
  fetch as fetchFaq,
  getIsLoaded as isFaqLoaded
} from 'redux/modules/faq'
import { fetch as fetchFeebackForm } from 'redux/modules/feedbackForm'
import {
  getIsLoaded as isGiftsHistoryLoaded,
  fetch as fetchGiftsHistory
} from 'redux/modules/giftsHistory'
import {
  fetch as getInvoice,
  fetchCount as getInvoiceCount,
  getIsLoaded as getIsInvoiceLoaded,
  resetParams as resetInvoiceParams
} from 'redux/modules/invoice'
import { fetchInvoice, fetchInvoiceItems } from 'redux/modules/invoiceDetails'
import { fetch as fetchInvoices } from 'redux/modules/invoices'
import {
  fetchSections as fetchLoyaltySections,
  getIsLoaded as isLoyaltySectionsLoaded,
  fetchStatus,
  setBarColor,
  fetchContent as fetchLoyaltyContent,
  fetchTarget,
  fetchCashback
} from 'redux/modules/loyalty'
import {
  fetch as fetchMarksMenu,
  getIsLoaded as isMarksMenuLoaded,
  fetchMarksSuppliers,
  isLoadedSuppliersSelector
} from 'redux/modules/marksMenu'
import { fetchMeta } from 'redux/modules/meta'
import { fetchNotifications } from 'redux/modules/notifications'
import {
  fetchHeaderOffer,
  fetch as fetchOffers,
  fetchOffer,
  fetchMarkdown,
  getOfferIsLoaded,
  isMarkdownsLoaded,
  getIsLoaded as isOffersLoaded,
  isDataOffersHeader,
  isLoadedOffersHeader,
  isLoadingOffersHeader
} from 'redux/modules/offers'
import {
  fetch as fetchOffices,
  getIsLoaded as getIsLoadedOffices
} from 'redux/modules/offices'
import { fetchOrderInfo, forInvoice } from 'redux/modules/order'
import { fetchOrderThanks, fetchOrderConfirm } from 'redux/modules/orders'
import { fetch as getOrderSummary } from 'redux/modules/orderSummary'
import { fetch as fetchPayments } from 'redux/modules/payments'
import {
  getIsLoaded as isPersonalInfoLoaded,
  getIsLoading as isPersonalInfoLoading,
  fetch as getPersonalInfo
} from 'redux/modules/personal'
import {
  fetch,
  getIsLoadedByGroupAndId,
  getIsBookmarkSearchLoaded,
  getIsIncorrectItemId,
  setBasketParams,
  fetchFiles,
  getIsFilesLoaded,
  fetchModifiers
} from 'redux/modules/productList'
import {
  fetch as fetchProducts,
  resetPreventNavigation,
  clearCheckedItems
} from 'redux/modules/products'
import { fetch as fetchCatalogFilters } from 'redux/modules/productsFilter'
import { initialSort } from 'redux/modules/productsParams'
import {
  getDirectories,
  getDirectoriesIsLoaded
} from 'redux/modules/registration'
import {
  fetch as fetchSections,
  fetchFavorite as fetchFavoriteSections,
  fetchDetail as fetchCatalogItemDetail,
  sectionsIsLoadedSelector,
  isLoadedSectionDetailSelector
} from 'redux/modules/sections'
import { catalogTypeSelector } from 'redux/modules/settings'
import { fetchDateUpdate } from 'redux/modules/shipment'
import {
  fetch as fetchStorage,
  getIsLoaded as isStorageLoaded,
  fetchSections as fetchStorageSections,
  fetchTopSections as fetchStorageTop,
  getIsLoaded as isStorageTopLoaded
} from 'redux/modules/storage'
import { hasMaterials } from 'redux/modules/storage/selector'
import { setTheme } from 'redux/modules/theme'
import { setParams, setLoaded } from 'redux/modules/updateData'
import parse from 'utils/querystring'

const helper = (helpers = {}) => {
  const { clientApi } = helpers
  let store

  const promiseAll =
    () =>
      (effects = []) => {
        const promise = Promise.all(effects)
        return promise
      }

  const updateData = {
    app: () => {
      const { getState } = store
      const catalogType = catalogTypeSelector(getState().settings)
      const effects = []
      let personalInfo = Promise.resolve()

      if (
        !(
          isPersonalInfoLoaded(getState().personal) ||
          isPersonalInfoLoading(getState().personal)
        )
      ) {
        personalInfo = store.dispatch(getPersonalInfo({}))
      }

      if (!getState().token.isUserWithoutPassword) {
        effects.push(
          personalInfo.then(() => {
            const personalPromises = []
            if (
              !(
                isPersonalInfoLoaded(getState().personal) ||
                isPersonalInfoLoading(getState().personal)
              )
            ) {
              personalPromises.push(store.dispatch(getPersonalInfo({})))
            }
            if (!isMarksMenuLoaded(getState().marksMenu)) {
              personalPromises.push(store.dispatch(fetchMarksMenu({})))
            }
            if (
              !sectionsIsLoadedSelector({
                catalogType,
                sections: getState().sections
              })
            ) {
              personalPromises.push(
                store.dispatch(fetchSections({ catalogType: 'main' }))
              )
            }
            return promiseAll()(personalPromises)
          })
        )
      } else {
        effects.push(personalInfo)
        if (!isMarksMenuLoaded(getState().marksMenu)) {
          effects.push(store.dispatch(fetchMarksMenu({})))
        }

        if (
          !sectionsIsLoadedSelector({
            catalogType,
            sections: getState().sections
          })
        ) {
          effects.push(store.dispatch(fetchSections({ catalogType: 'main' })))
        }
      }

      effects.push(
        store.dispatch(
          fetchMeta({
            url:
              `${getState().router.location.pathname}${
                getState().router.location.search
              }` || '/'
          })
        )
      )

      return promiseAll()(effects)
    },

    api: () => {
      const effects = []
      effects.push(store.dispatch(fetchKeys({})))
      return promiseAll()(effects)
    },

    homeCarousels: () => {
      const { getState } = store
      const effects = [
        store.dispatch(fetch({ type: 'carousel', params: { limit: 12 } })),
        store.dispatch(fetchFavoriteSections({}))
      ]
      if (!isLoadedSuppliersSelector(getState().marksMenu)) {
        effects.push(store.dispatch(fetchMarksSuppliers({})))
      }
      return promiseAll()(effects)
    },

    marksMenu: () => {
      const { getState } = store
      const effects = []
      if (!isMarksMenuLoaded(getState().marksMenu)) {
        effects.push(store.dispatch(fetchMarksMenu({})))
      }
      return promiseAll()(effects)
    },

    catalog: () => {
      const { id, brand, loyalty } = updateData.get()
      const { getState } = store
      const effects = []

      if (brand) {
        if (!getIsLoadedByGroupAndId('brand', brand, getState().productList)) {
          effects.push(
            store.dispatch(
              fetch({
                id: brand,
                type: 'brand'
              })
            )
          )
        }
      } else if (loyalty && !getState().token.isUserWithoutPassword) {
        if (
          !getIsLoadedByGroupAndId('loyalty', loyalty, getState().productList)
        ) {
          effects.push(
            store.dispatch(
              fetch({
                id: loyalty,
                type: 'loyalty'
              })
            )
          )
        }
      } else {
        effects.push(
          store.dispatch(
            fetch({
              id,
              type: 'section'
            })
          )
        )
      }
      return promiseAll()(effects)
    },

    catalogListItem: () => {
      const { id, bookmark, url } = updateData.get()
      const { getState } = store
      const effects = []
      const catalogType = catalogTypeSelector(getState().settings)
      if (
        !isLoadedSectionDetailSelector({
          state: getState().sections,
          sectionId: id
        })
      ) {
        effects.push(
          store.dispatch(
            fetchCatalogItemDetail({ catalogType, bookmark, sectionId: id })
          )
        )
      }
      effects.push(store.dispatch(fetchMeta({ url, section_id: id })))
      return promiseAll()(effects)
    },

    favorite: () => {
      const { getState } = store
      const effects = []

      if (!getState().token.isUserWithoutPassword) {
        effects.push(
          store.dispatch(
            fetch({
              id: '0',
              type: 'favorite'
            })
          )
        )
      }

      return promiseAll()(effects)
    },

    compare: () => {
      const effects = []

      effects.push(
        store.dispatch(
          fetchCompare({
            offset: 0
          })
        )
      )

      return promiseAll()(effects)
    },

    feedbackForm: () => {
      const { getState } = store
      const effects = []
      effects.push(store.dispatch(fetchFeebackForm(getState().personal)))
      return promiseAll()(effects)
    },

    search: () => {
      const effects = []
      return promiseAll()(effects)
    },

    preventNavigation: () => store.dispatch(resetPreventNavigation()),

    products: () => {
      const effects = []
      const {
        url,
        type,
        query = {},
        cookieParams,
        pageId,
        forceUpdate: forceParam,
        basket,
        isSameQuery
      } = updateData.get()
      const forceUpdate = forceParam
      const { getState } = store
      const location = pathOr({}, ['router', 'location'], getState())
      const queryParams = parse(prop('search', location))
      const filtersQuery = propOr('', 'filters', queryParams)
      const isNewPage = forceUpdate || pageId !== getState().products.pageId
      const isNewPageCatalog = isEmpty(queryParams) && ''
      const checkedItemsIds = Object.keys(
        pathOr({}, ['products', 'itemsCheckedMap'], getState())
      )
      const locationParams = pathOr({}, ['state', 'params'], location)
      let filters = null
      let count
      const page = Number(propOr(1, 'p', queryParams))
      let params = { ...getState().productsParams }
      if (isNewPage) {
        params = { ...params, ...cookieParams }
        if (type === 'search' && !isSameQuery) {
          params = { ...params, ...initialSort }
        }
        filters = filtersFromQuery(filtersQuery)
      } else {
        const stateFilters = pathOr({}, ['state', 'filters'], location)
        filters = {
          ...filters,
          ...filtersFromQuery(filtersQuery),
          ...stateFilters
        }
        if (pathOr(false, ['state', 'updateFilters'], location)) {
          count = path(['productsFilter', 'count'], getState())
        }
      }
      params = { ...params, ...locationParams, page, type }
      params.offset = Number.isNaN(page - 1) ? 0 : (page - 1) * params.limit
      if (isNewPageCatalog === '' && checkedItemsIds.length !== 0) {
        effects.push(store.dispatch(clearCheckedItems({})))
      }
      effects.push(
        store.dispatch(
          fetchCatalogFilters({
            url: `${url}/aggregation/`,
            filter: filters,
            params:
              is(Object, filters) && !isEmpty(filters)
                ? {
                  ...pick(['section_id', 'q', 'correct'], query),
                  postFilter: true
                }
                : pick(['section_id', 'q', 'correct'], query)
          })
        )
      )
      effects.push(
        store.dispatch(
          fetchProducts({
            pageId,
            url,
            params,
            type,
            query,
            filters,
            count,
            basket,
            forceUpdate,
            isUpdateFilters: path(['state', 'updateFilters'], location),
            isResetFilters: path(['state', 'isResetFilters'], location)
          })
        )
      )

      effects.push(
        store.dispatch(
          fetchMeta({
            url: propOr('', 'pathname', location),
            section_id: path(['section_id'], query)
          })
        )
      )
      return promiseAll()(effects)
    },

    loyaltySearch: () => {
      const { id, correct = 'Y' } = updateData.get()
      const effects = []
      effects.push(
        store.dispatch(
          fetch({
            id,
            params: { correct },
            type: 'loyaltySearch'
          })
        )
      )
      return promiseAll()(effects)
    },

    productPage: () => {
      const { productId: id, mode, url } = updateData.get()
      const { getState } = store
      const bookmark = getState().settings.bookmark || undefined
      const effects = []
      let catalog = 'main'
      const sectionId = pathOr(
        '',
        ['productList', 'itemsById', id, 'SECTION', 'ID'],
        getState()
      )
      if (mode === 'loyalty' && !getState().token.isUserWithoutPassword) {
        catalog = 'loyalty'
        effects.push(
          store.dispatch(
            fetch({
              id,
              type: 'loyaltyProduct',
              catalog,
              params: { section_id: sectionId }
            })
          )
        )
      } else if (bookmark) {
        effects.push(
          store.dispatch(
            fetch({
              id,
              type: 'product',
              catalog: 'bookmark',
              params: {
                bookmark_id: bookmark,
                section_id: sectionId
              }
            })
          )
        )
      } else {
        effects.push(
          store.dispatch(
            fetch({
              id,
              type: 'product',
              catalog,
              params: { section_id: sectionId }
            })
          )
        )
      }

      return promiseAll()(effects).then(() => {
        const addPromises = []
        const { productList } = getState()

        // при некорректном id товара устанавливаем флаг и отменяем подгрузку остальных данных
        if (getIsIncorrectItemId('product', id, productList)) {
          return updateData.set({ productError: true })
        }
        if (!getIsFilesLoaded(id, productList)) {
          addPromises.push(
            store.dispatch(
              fetchFiles({
                id
              })
            )
          )
        }
        const group = pathOr(
          '',
          ['productList', 'itemsById', id, 'MODIFIERS_GROUP'],
          getState()
        )
        addPromises.push(
          store.dispatch(
            fetchModifiers({
              id,
              group
            })
          )
        )
        addPromises.push(
          store.dispatch(
            fetchMeta({
              url,
              section_id: pathOr(
                '',
                ['itemsById', id, 'SECTION', 'ID'],
                productList
              )
            })
          )
        )
        return promiseAll()(addPromises)
      })
    },

    basket: () => {
      const { dispatch } = store
      const {
        catalog = 'main',
        value = 0,
        page,
        basketParams: { limit, sort, order }
      } = updateData.get()
      const offset = Number.isNaN(page - 1) ? 0 : (page - 1) * limit
      const effects = []
      const params = {}
      let id = catalog

      if (value && value > 0) {
        id = `${catalog}_${value}`
        params.value = value
        if (catalog === 'bookmark') {
          effects.push(
            dispatch(
              fetchSections({ catalogType: 'bookmark', bookmark: value })
            )
          )
        }
      }
      params.page = page
      params.offset = offset
      params.limit = limit
      params.sort = sort
      params.order = order

      effects.push(
        dispatch(
          setBasketParams({
            limit,
            sort,
            order
          })
        )
      )
      effects.push(
        dispatch(
          fetch({
            id,
            type: 'basket',
            catalog,
            params
          })
        )
      )

      return promiseAll()(effects)
    },

    orderConfirm: () => {
      const { id, type } = updateData.get()
      const effects = []
      effects.push(store.dispatch(fetchOrderConfirm({ id, type })))

      return promiseAll()(effects)
    },

    orderThanks: () => {
      const { id } = updateData.get()
      const effects = []
      effects.push(store.dispatch(fetchOrderThanks(id)))

      return promiseAll()(effects)
    },

    forInvoice: () => {
      const { id } = updateData.get()
      const effects = []
      effects.push(store.dispatch(forInvoice({ guid: id })))

      return promiseAll()(effects)
    },

    invoiceBill: () => {
      const { id } = updateData.get()
      const effects = []
      effects.push(store.dispatch(fetchInvoiceItems({ id })))

      return promiseAll()(effects)
    },

    invoiceShipment: () => {
      const { id } = updateData.get()
      const effects = []
      effects.push(store.dispatch(fetchInvoice({ id, type: 'shipment' })))

      return promiseAll()(effects)
    },

    invoicesContainer: () => {
      const { getState } = store

      const defaultTabs = path(['invoices', 'defaultTabs'], getState())
      const location = pathOr({}, ['router', 'location'], getState())
      const queryParams = parse(propOr('', 'search', location))
      const tab = propOr('searchBill', 'tab', queryParams)
      const page = Number(propOr(1, 'p', queryParams))
      const sort = propOr('', 'sort', queryParams)
      const order = propOr('', 'order', queryParams)
      const limit = Number(propOr('48', 'l', queryParams))
      const offset = multiply(limit)(dec(page))
      const index = findIndex(propEq('id', tab), defaultTabs)
      const listType = path([index, 'listType'], defaultTabs)
      const data = { ...queryParams, limit, listType, offset, page, tab, sort, order}

      const effects = []
      effects.push(store.dispatch(fetchInvoices(data)))
      return promiseAll()(effects)
    },

    orderSummary: () => {
      const { orderId, page } = updateData.get()

      const effects = []
      effects.push(store.dispatch(getOrderSummary({ orderId, page })))

      return promiseAll()(effects)
    },

    directories: () => {
      const { getState } = store
      const effects = []
      if (!getDirectoriesIsLoaded(getState().registration)) {
        effects.push(store.dispatch(getDirectories({})))
      }

      return promiseAll()(effects)
    },

    loyalty: () => {
      const { getState } = store
      const { theme } = updateData.get()
      const effects = []
      if (!getState().token.isUserWithoutPassword) {
        if (!isLoyaltySectionsLoaded('status', getState().loyalty)) {
          effects.push(store.dispatch(fetchStatus({})))
        }

        if (!isLoyaltySectionsLoaded('sections', getState().loyalty)) {
          effects.push(store.dispatch(fetchLoyaltySections({})))
        }

        if (!isLoyaltySectionsLoaded('content', getState().loyalty)) {
          effects.push(store.dispatch(fetchLoyaltyContent({})))
        }

        if (!isLoyaltySectionsLoaded('target', getState().loyalty)) {
          effects.push(store.dispatch(fetchTarget({})))
        }

        effects.push(
          store.dispatch(
            fetch({
              id: '0',
              type: 'loyaltyBasket'
            })
          )
        )

        if (theme) {
          effects.push(store.dispatch(setTheme(theme)))
        }

        // eslint-disable-next-line no-magic-numbers
        store.dispatch(setBarColor(Math.floor(Math.random() * 4)))
      }
      return promiseAll()(effects)
    },

    loyaltyHome: () => {
      const { getState } = store
      const effects = []

      if (!getState().token.isUserWithoutPassword) {
        effects.push(
          store.dispatch(
            fetch({
              type: 'carousel',
              catalog: 'loyalty',
              params: {
                limit: 12,
                'types[]': 'novel'
              }
            })
          )
        )
      }

      return promiseAll()(effects)
    },

    loyaltyCashback: () => {
      const { getState } = store
      const effects = []
      if (!getState().token.isUserWithoutPassword) {
        effects.push(store.dispatch(fetchCashback({ period: 'currentMonth' })))
        effects.push(store.dispatch(fetchCashback({ period: 'feautureMonth' })))
      }

      return promiseAll()(effects)
    },

    content: () => {
      const effects = []
      const { id, type } = updateData.get()

      effects.push(store.dispatch(fetchContent({ id, type })))

      return promiseAll()(effects)
    },

    contentItems: () => {
      const effects = []
      const { type, sort, order, offset, limit, filter } = updateData.get()

      effects.push(
        store.dispatch(
          fetchContents({ type, sort, order, offset, limit, filter })
        )
      )

      return promiseAll()(effects)
    },

    contentMenu: () => {
      const effects = []

      effects.push(store.dispatch(fetchContentMenu({})))

      return promiseAll()(effects)
    },

    document: () => {
      const { getState } = store
      const effects = []
      const { url } = updateData.get()

      if (url && !isContentLoaded(url, getState().content)) {
        effects.push(store.dispatch(fetchContentOld(url)))
      }

      return promiseAll()(effects)
    },

    bookmarks: () => {
      const { getState } = store
      const { bookmark } = updateData.get()
      const effects = []
      if (!getState().token.isUserWithoutPassword) {
        if (
          !sectionsIsLoadedSelector({
            catalogType: 'bookmark',
            sections: getState().sections,
            bookmark
          })
        ) {
          effects.push(
            store.dispatch(fetchSections({ catalogType: 'bookmark', bookmark }))
          )
        }
        if (!isBookmarkLoaded(getState().bookmarks)) {
          effects.push(store.dispatch(fetchBookmarks({})))
        }
      }
      return promiseAll()(effects)
    },

    offices: () => {
      const { getState } = store
      const effects = []
      if (!getIsLoadedOffices(getState().offices)) {
        effects.push(store.dispatch(fetchOffices({})))
      }
      return promiseAll()(effects)
    },

    bookmark: () => {
      const { bookmark, id } = updateData.get()
      const { getState } = store
      const effects = []
      if (
        bookmark &&
        id &&
        !getIsLoadedByGroupAndId(
          'bookmark',
          `${bookmark}_${id}`,
          getState().productList
        )
      ) {
        effects.push(
          store.dispatch(
            fetch({
              id,
              params: { bookmark },
              type: 'bookmark'
            })
          )
        )
      }
      return promiseAll()(effects)
    },

    bookmarkSearch: () => {
      const { id, bookmark } = updateData.get()
      const { getState } = store
      const effects = []
      if (
        !getIsBookmarkSearchLoaded(`${bookmark}_${id}`, getState().productList)
      ) {
        effects.push(
          store.dispatch(
            fetch({
              id,
              params: { bookmark },
              type: 'bookmarkSearch'
            })
          )
        )
      }
      return promiseAll()(effects)
    },

    checkout: () => {
      const effects = []
      const { getState } = store
      const { id } = updateData.get()

      if (!getState().token.isUserWithoutPassword) {
        effects.push(store.dispatch(fetchOrderInfo({ guid: id })))
      }
      return promiseAll()(effects)
    },

    cabinet: () => {
      const effects = []
      const { getState } = store

      if (!getState().token.isUserWithoutPassword) {
        if (!getState().contractors.address) {
          effects.push(store.dispatch(fetchAddress({})))
        }
      }
      return promiseAll()(effects)
    },

    cabinetDiscounts: () => {
      const effects = []
      const { getState } = store

      if (
        !getState().token.isUserWithoutPassword &&
        !isLoadedDiscounts(getState().discounts)
      ) {
        effects.push(store.dispatch(fetchDiscounts({})))
        effects.push(store.dispatch(fetchDateUpdate({ type: 'shipment'})))
      }
      return promiseAll()(effects)
    },

    cabinetCompany: () => {
      const effects = []
      const { getState } = store

      if (!getState().token.isUserWithoutPassword) {
        if (!isContractorsLoaded(getState().contractors)) {
          effects.push(store.dispatch(fetchContractors({})))
        }
      }
      return promiseAll()(effects)
    },

    cabinetGraphic: () => {
      const effects = []
      const { getState } = store

      if (!getState().token.isUserWithoutPassword) {
        effects.push(store.dispatch(fetchPayments({})))
      }
      return promiseAll()(effects)
    },

    employees: () => {
      const { getState } = store
      const effects = []

      if (
        !getState().token.isUserWithoutPassword &&
        !isEmployeesLoaded(getState().employees)
      ) {
        effects.push(store.dispatch(fetchEmployees({})))
        effects.push(store.dispatch(fetchPersonal({})))
      }
      return promiseAll()(effects)
    },

    invoice: () => {
      const { getState } = store
      const { mode, query } = updateData.get()
      const effects = []

      if (!getState().token.isUserWithoutPassword) {
        if (mode === 'loyalty') {
          effects.push(store.dispatch(getInvoice({ mode })))
        } else {
          if (getIsInvoiceLoaded(getState().invoice) && isEmpty(query)) {
            store.dispatch(resetInvoiceParams({}))
          }
          const filtersFromState = {
            limit: getState().invoice.limit
          }
          const filters = cabinetFiltersFromQuery(query, filtersFromState)
          const params = {
            offset: 0,
            ...filters
          }
          effects.push(store.dispatch(getInvoice(params)))
          effects.push(store.dispatch(getInvoiceCount(params)))
        }
      }

      return promiseAll()(effects)
    },

    cabinetAnalytics: () => {
      const { getState } = store
      const { query, isDesktop } = updateData.get()
      const effects = []
      if (!getState().token.isUserWithoutPassword) {
        const params = parseFilters(query)
        effects.push(
          store.dispatch(fetchAnalytics({ params, type: 'aggregation' }))
        )
        if (isDesktop) {
          effects.push(store.dispatch(fetchAnalytics({ params, type: 'graph' })))
          effects.push(
            store.dispatch(fetchAnalytics({ params, type: 'diagram' }))
          )
        }
        effects.push(store.dispatch(fetchAnalytics({ params, type: 'list' })))
      }

      return promiseAll()(effects)
    },

    notifications: () => {
      const { page, limit, offset, sort, order, filter } = updateData.get()
      const effects = []
      effects.push(
        store.dispatch(
          fetchNotifications({ ...filter, page, limit, offset, sort, order })
        )
      )
      return promiseAll()(effects)
    },

    storage: () => {
      const { getState } = store
      const effects = []

      if (!isStorageLoaded(getState().storage)) {
        effects.push(store.dispatch(fetchStorage({})))
      }

      if (!isStorageTopLoaded(getState().storage)) {
        effects.push(store.dispatch(fetchStorageTop({})))
      }

      return promiseAll()(effects)
    },

    storageSections: () => {
      const { getState } = store
      const { id } = updateData.get()
      const effects = []

      if (!isStorageLoaded(getState().storage)) {
        effects.push(store.dispatch(fetchStorage({ id })))
      }

      if (!isStorageTopLoaded(getState().storage)) {
        effects.push(store.dispatch(fetchStorageTop({})))
      }

      if (!hasMaterials(id, getState().storage)) {
        effects.push(store.dispatch(fetchStorageSections({ id })))
      }
      return promiseAll()(effects)
    },

    markdown: () => {
      const { getState } = store
      const effects = []
      if (!isMarkdownsLoaded(getState().offers)) {
        effects.push(store.dispatch(fetchMarkdown({})))
      }
      return promiseAll()(effects)
    },

    offline: () => {
      const { getState } = store
      const { offerType } = updateData.get()
      const effects = []
      if (!isOffersLoaded(getState().offers, offerType)) {
        effects.push(store.dispatch(fetchOffers({ offerType })))
      }
      return promiseAll()(effects)
    },

    offer: () => {
      const { getState } = store
      const { id } = updateData.get()
      const effects = []
      if (isEmpty(getOfferIsLoaded(getState().offers, id))) {
        effects.push(store.dispatch(fetchOffer({ offer_id: id })))
      }
      return promiseAll()(effects)
    },

    offersMain: () => {
      const { getState } = store
      const effects = []
      if (
        not(isDataOffersHeader(getState().offers)) &&
        not(isLoadedOffersHeader(getState().offers)) &&
        not(isLoadingOffersHeader(getState().offers))) {
        effects.push(store.dispatch(fetchHeaderOffer({})))
      }
      return promiseAll()(effects)
    },

    formGetPrize: () => {
      const { getState } = store
      const effects = []
      if (!getIsLoadedFullAddress(getState().contractors)) {
        effects.push(store.dispatch(fetchFullAddress({})))
      }
      return promiseAll()(effects)
    },

    brandsList: () => {
      const { getState } = store
      const { type } = updateData.get()
      const effects = []
      const section = pathOr(
        '',
        ['router', 'location', 'query', 'section'],
        getState()
      )
      const sectionAll = 'all'

      if (
        !isLoadedBrandsSection({
          brands: getState().brands,
          section: sectionAll
        })
      ) {
        effects.push(store.dispatch(fetchBrands({ type, store: sectionAll })))
      }
      if (
        !isEmpty(section) &&
        !isLoadedBrandsSection({ brands: getState().brands, section })
      ) {
        effects.push(
          store.dispatch(
            fetchBrands({
              type,
              section_id: section
            })
          )
        )
      }
      if (!isLoadedManufacturesSelector(getState().brands) && type === 'tm') {
        effects.push(store.dispatch(fetchManufactures()))
      }
      return promiseAll()(effects)
    },

    faq: () => {
      const { getState } = store
      const effects = []
      if (!isFaqLoaded(getState().faq)) {
        effects.push(store.dispatch(fetchFaq({})))
      }
      return promiseAll()(effects)
    },

    giftsHistory: () => {
      const { getState } = store
      const effects = []

      if (!isGiftsHistoryLoaded(getState().giftsHistory)) {
        effects.push(store.dispatch(fetchGiftsHistory({})))
      }

      return promiseAll()(effects)
    },

    sitemap: () => {
      const effects = []
      const sectionAll = 'all'
      effects.push(store.dispatch(fetchBrands({ store: sectionAll })))

      return promiseAll()(effects)
    },

    get: () => store.getState().updateData,

    set: params => store.dispatch(setParams(params)),

    setStore: _store => {
      store = _store
    },

    update: (view = updateData.get().view) => {
      return composeP(
        () => Promise.resolve({ updateData }),
        () => updateData.updateView(false, view),
        clientApi.getTokenFirstTime,
        () => store.dispatch(setLoaded(false))
      )()
    },

    updateView: (force = false, view = updateData.get().view) => {
      return (
        (updateData[view] && updateData[view](force)) ||
        Promise.resolve()
      ).then(() => store.dispatch(setLoaded(true)))
    },

    updateAll: () => {
      const effects = [updateData.app(true), updateData.updateView(true)]

      return promiseAll()(effects)
    }
  }
  return updateData
}

export default helper
