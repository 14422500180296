/* eslint-disable no-magic-numbers */
const environment = {
  development: {
    isProduction: false,
    isDev: true
  },
  production: {
    isProduction: true,
    isDev: false
  }
}[process.env.NODE_ENV || 'development']

export default {
  title: 'relefopt',
  domainStatic: process.env.DOMAIN_STATIC || 'https://relefopt.ru',
  domain: process.env.DOMAIN || 'https://relefopt.ru/',
  host: process.env.HOST || 'localhost',
  port: process.env.PORT || 3000,
  apiHost: process.env.APIHOST || 'localhost',
  apiPort: process.env.APIPORT || 3031,
  apiServer: process.env.APISERVER || 'https://relefopt.ru/',
  authServer: process.env.AUTHSERVER || 'https://relefopt.ru/',
  socket: {
    isActive: Boolean(Number(process.env.SOCKET_ACTIVE || 1)),
    server: process.env.SOCKET_SERVER || 'https://relefopt.ru:8888',
    config: {
      reconnection: true,
      reconnectionDelay: 10000,
      reconnectionDelayMax: 50000,
      reconnectionAttempts: 10,
      transports: ['websocket'],
      autoConnect: false
    }
  },
  isDisableAjax: Boolean(Number(process.env.DISABLE_AJAX || 0)),
  isDisableSsr: Boolean(Number(process.env.DISABLE_SSR || 0)),
  kladrToken: process.env.KLADR_TOKEN || '57d649510a69de06468b45cb',
  sentry: {
    isActive: Boolean(Number(process.env.SENTRY_ACTIVE || 1)),
    dsn:
      process.env.SENTRY_DSN ||
      'https://740b8159e69c4ca0a2c0813f67ce2a8e@o1077272.ingest.sentry.io/6080055'
  },
  formIo: {
    isActive: Boolean(Number(process.env.FORM_IO_ACTIVE || 0)),
    host: process.env.FORM_IO || 'https://formio-proxy.relefopt.relef.dev'
  },
  googleMapURL:
    process.env.MAPS ||
    'https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyBmUdMl-WkPnLhTSZ-DQcu-hsMWn-ebQXw',
  google: {
    timeout: Number(process.env.GOOGLE_TIMEOUT || 3000),
    isActive: Boolean(Number(process.env.GOOGLE_ACTIVE || 0))
  },
  vk: {
    isActive: Boolean(Number(process.env.VK_ACTIVE || 0))
  },
  kanzoboz: {
    timeout: Number(process.env.KANZOBOZ_TIMEOUT || 3000)
  },
  serviceWorker: {
    isActive: Boolean(Number(process.env.SERVICE_WORKER_ACTIVE || 0))
  },
  metrics: {
    isActive: Boolean(Number(process.env.METRICS_ACTIVE || 1))
  },
  apiAuth: {
    credentials: {
      client_id: process.env.CLIENT_ID || 'relefopt',
      client_secret:
        process.env.CLIENT_SECRET || 'e4c25cec64c71d10001ae4c768fb9fc76c5193be'
    },
    change_client: Boolean(Number(process.env.CHANGE_CLIENT || 0))
  },
  loyall: {
    api_key: process.env.LOYALL_KEY || '4dfd684938dc8ebe6f8c5c5a4a92fc72'
  },
  dadata: {
    url: process.env.DADATA_URL || 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party',
    apiKey: process.env.DADATA_KEY || '3026f35266fdd3e352d8b80768606e5adefc6300'
  },
  lazy: {
    isActive: Boolean(Number(process.env.LAZY_ACTIVE || 1))
  },
  loyaltyOrderMaxLimit: 1000,
  filterItemsInView: 5,
  delayDynamicPart: Number(process.env.DELAY_DYNAMIC_PART || 200),
  clientThemes: {
    relefoffice: 'default',
    relefopt: 'default'
  },
  themes: {
    default: {
      company: 'Рельеф-Центр',
      boxing: 'Партия / упаковка',
      year: '2020',
      logo: 'logo-default',
      icons: {
        phone: 'icon-phone',
        history: 'clock',
        personalCabinet: 'suitcase',
        help: 'icon-help',
        '918ok': '9-18ok',
        whyMe: 'hands',
        registration: 'icon-lk',
        cart: 'cart-header',
        loyaltyLogo: 'logo-jam-new',
        offers: 'icon-offers'
      }
    },
    violet: {
      company: 'Рельеф-Центр',
      boxing: 'Партия / упаковка',
      year: '2020',
      logo: 'logo-jam-new',
      icons: {
        phone: 'icon-phone',
        history: 'clock',
        personalCabinet: 'suitcase',
        loyaltyLogo: 'logo-jam-new',
        loyaltyStatus: 'star-status',
        loyaltyCart: 'cart-loyaltyHeader',
        loyaltyCashback: 'percent',
        cart: 'cart-loyalty',
        headerCatalog: 'catalog-header',
        iconGear: 'icon-gear'
      }
    }
  },
  ...environment
}
