/* eslint-disable complexity */
import {
  pathOr,
  prop,
  equals,
  propOr,
  findLast,
  propEq,
  compose,
  props,
  defaultTo,
  map,
  sort,
  not,
  find,
  omit,
  path,
  pick,
  reduce,
  filter,
  values,
  mapObjIndexed,
  length,
  lte,
  includes,
  prepend
} from 'ramda'
import { createSelector } from 'reselect'
// import Fuse from 'fuse.js'

import { snakeToCamel } from 'helpers/toCamelCase'
import moment from 'utils/moment'

import { ORDER, PROGRESS_2, PROGRESS_3, PROGRESS_4, PROGRESS_5, PROGRESS_10 } from './index'

export const ID_TK = '44cc8d8e-28b2-11ea-ae3d-08606ec376a1'

export const confirmSelector = createSelector(
  order => order,
  (_, ownProps) => prop('catalog', ownProps),
  (_, ownProps) => prop('bookmark', ownProps),
  (_, ownProps) => prop('sum', ownProps),
  (_, ownProps) => prop('sendActive', ownProps),
  (order, catalog, bookmark, sum, sendActive) =>
    sendActive &&
    equals(
      pathOr(0, ['confirm', `${ORDER}_${catalog}_${bookmark}`], order),
      sum
    )
)

export const deliveryIdSelector = createSelector(
  order => order,
  order => prop('deliveryId', order)
)

export const deliveryDateSelector = createSelector(
  order => order,
  order => prop('deliveryDate', order)
)

export const deliveryDatesSelector = createSelector(
  order => order,
  order => propOr([], 'deliveryDates', order)
)

export const addressSelector = createSelector(
  order => order,
  order => propOr([], 'address', order)
)

export const addressIdSelector = createSelector(
  order => order,
  order => prop('addressId', order)
)

export const scheduleFromIdSelector = createSelector(
  addressIdSelector,
  addressSelector,
  (addressId, address) =>
    compose(
      propOr([], 'WORK_SCHEDULE'),
      findLast(propEq('ID', addressId))
    )(address)
)

export const prepareDeliveryDatesSelector = createSelector(
  deliveryDatesSelector,
  deliveryDates =>
    compose(
      map(item => ({
        value: item,
        title: moment(item, 'X').locale('ru').format('D MMMM, dddd')
      })),
      sort((a, b) => a - b)
    )(deliveryDates)
)

export const selectedIndexSelector = createSelector(
  deliveryDateSelector,
  prepareDeliveryDatesSelector,
  deliveryDate => deliveryDate
)

const selectedDaySelector = createSelector(
  prepareDeliveryDatesSelector,
  selectedIndexSelector,
  (prepareDeliveryDates, selectedIndex) =>
    compose(
      dayEn => dayEn.toUpperCase(),
      time => moment(time, 'X').locale('en').format('dddd'),
      propOr(null, 'id'),
      pathOr([], [selectedIndex])
    )(prepareDeliveryDates)
)

export const isNotWorkingsDaySelector = createSelector(
  scheduleFromIdSelector,
  selectedDaySelector,
  (schedule, selectedDay) =>
    compose(
      not(),
      propOr(true, 'IS_WORKS_DAY'),
      find(propEq('DAY_NAME', selectedDay))
    )(schedule)
)

export const addressSearchValueSelector = createSelector(
  addressSelector,
  addressIdSelector,
  order => prop('addressSearch', order),
  (address, addressId, addressSearch) =>
    addressId
      ? propOr('', 'TITLE', findLast(propEq('ID', addressId), address))
      : addressSearch
)

export const isCommentSelector = createSelector(
  order => order,
  order => propOr(false, 'isComment', order)
)

export const commentSelector = createSelector(
  order => order,
  order => prop('comment', order)
)

export const deliverySelector = createSelector(
  order => order,
  order => propOr([], 'delivery', order)
)

export const isAddressSelector = createSelector(
  deliveryIdSelector,
  deliverySelector,
  (id, delivery) =>
    compose(
      equals([true, true]),
      props(['NEED_ADDRESS', 'SHOW_POSSIBLE_DELIVERY_DATE']),
      defaultTo({}),
      findLast(propEq('ID', id))
    )(delivery)
)

export const isTkSelector = createSelector(
  deliveryIdSelector,
  deliverySelector,
  (id, delivery) =>
    compose(
      equals([ID_TK]),
      props(['ID']),
      defaultTo({}),
      findLast(propEq('ID', id))
    )(delivery)
)

const deliveryDateAccessSelector = createSelector(
  (_, state) => state,
  personal =>
    pathOr(false, ['accesses', 'SHOW_POSSIBLE_DELIVERY_DATE'], personal)
)

export const isPossibleDateSelector = createSelector(
  deliveryIdSelector,
  deliverySelector,
  deliveryDateAccessSelector,
  (id, delivery, access) =>
    access &&
    compose(
      equals(true),
      prop('SHOW_POSSIBLE_DELIVERY_DATE'),
      findLast(propEq('ID', id))
    )(delivery)
)

export const addressSearchSelector = createSelector(
  addressSelector,
  address => address
)

export const orderTypeSelector = createSelector(
  order => order,
  order => propOr([], 'orderType', order)
)

export const orderTypeIdSelector = createSelector(
  order => order,
  order => propOr(0, 'orderTypeId', order)
)

const newTkSelector = createSelector(
  _ => _,
  _ => ({
    ID: 0,
    TITLE: 'Другая',
    HASH: _
  })
)

const newTkContactSelector = createSelector(
  _ => _,
  _ => ({
    ID: 0,
    TITLE: 'Другое',
    HASH: _
  })
)

export const tkSelector = createSelector(
  state => state,
  newTkSelector,
  (state, newTk) => [newTk, ...propOr([], 'tk', state)]
)

export const tkIdSelector = createSelector(
  state => state,
  state => propOr('', 'tk_id', state)
)

export const tkTitleSelector = createSelector(
  state => state,
  state => propOr('', 'tk_title', state)
)

export const tkContactSelector = createSelector(
  state => state,
  newTkContactSelector,
  (state, newTkContact) => [newTkContact, ...propOr([], 'tk_contact', state)]
)

export const tkContactIdSelector = createSelector(
  state => state,
  state => propOr('', 'tk_contact_id', state)
)

export const tkAddressSelector = createSelector(
  state => state,
  state => propOr([], 'tk_address', state)
)

export const tkAddressIdSelector = createSelector(
  state => state,
  state => propOr('', 'tk_address_id', state)
)

export const tkAddressTitleSelector = createSelector(
  state => state,
  state => propOr('', 'tk_address_title', state)
)

export const tkContactNameSelector = createSelector(
  state => state,
  state => propOr('', 'tk_contact_name', state)
)

export const tkContactPhoneSelector = createSelector(
  state => state,
  state => propOr('', 'tk_contact_phone', state)
)

export const tkTypeSelector = createSelector(
  state => state,
  state => propOr([], 'tk_type', state)
)

export const tkTypeIdSelector = createSelector(
  state => state,
  state => propOr('', 'tk_delivery_id', state)
)

export const tkConsigneeSelector = createSelector(
  state => state,
  state => propOr('', 'tk_consignee', state)
)

export const tkTransportSelector = createSelector(
  state => state,
  state => propOr([], 'tk_transport', state)
)

export const tkTransportIdSelector = createSelector(
  state => state,
  state => propOr('', 'tk_transport_id', state)
)

export const tkServiceSelector = createSelector(
  state => state,
  state => propOr([], 'tk_service', state)
)

export const tkServicesSelector = createSelector(
  state => state,
  state => propOr({}, 'tk_services', state)
)

const tkSelectedSelector = createSelector(
  tkIdSelector,
  tkTitleSelector,
  (id, name) => id || name
)

export const progressSelector = createSelector(
  deliveryIdSelector,
  addressIdSelector,
  isAddressSelector,
  tkSelectedSelector,
  tkTypeIdSelector,
  isTkSelector,
  (
    delivery,
    address,
    isNeedAddress,
    tkSelected,
    tkType,
    isTk
  ) => {
    if (delivery && isTk && !tkSelected) {
      return PROGRESS_4
    }

    if (delivery && isTk && !tkType) {
      return PROGRESS_5
    }

    if (delivery && isTk && tkSelected && tkType) {
      return PROGRESS_10
    }

    if (delivery && address) {
      return PROGRESS_10
    }

    if (delivery && !isNeedAddress) {
      return PROGRESS_10
    }

    if (delivery && isNeedAddress) {
      return PROGRESS_3
    }

    return PROGRESS_2
  }
)

const forInvoiceSelector = createSelector(
  ({ order }) => order,
  ({ guid }) => guid,
  (orders, guid) => pathOr({}, ['forInvoice', guid], orders)
)

export const isLoadingForInvoiceSelector = createSelector(
  forInvoiceSelector,
  order => pathOr(true, ['isLoading'], order)
)

export const isErrorForInvoiceSelector = createSelector(
  forInvoiceSelector,
  order => pathOr(false, ['isError'], order)
)

export const errorForInvoiceSelector = createSelector(
  forInvoiceSelector,
  order => pathOr('', ['error'], order)
)

const orderSelector = createSelector(
  ({ order }) => order,
  ({ guid }) => guid,
  (orders, guid) => {
    return pathOr({}, ['orders', guid], orders)
  }
)

const storeSelector = createSelector(orderSelector,
  order => pathOr({}, ['data', 'ENTITIES', 'STORE'], order))

const orderReceipt = createSelector(orderSelector,
  order => pathOr([], ['data', 'RECEIPT'], order)
)

export const orderDeliveryIdSelector = createSelector(orderSelector,
  order => pathOr(null, ['data', 'ITEMS', 0, 'DELIVERY', 'TYPE'], order)
)

export const commentOrderSelector = createSelector(orderSelector,
  order => pathOr('', ['data', 'ITEMS', 0, 'COMMENT'], order)
)

export const orderInfoSelector = createSelector(orderReceipt,
  order => reduce(
    (acc, item) => ({ ...acc, [snakeToCamel(prop('CODE', item))]: Number(prop('VALUE', item)) }),
    {},
    order
  )
)

export const orderSendingSelector = createSelector(orderSelector, order =>
  pathOr(false, ['isSending'], order)
)

export const isRevertOrderSelector = createSelector(orderSelector,
  order => {
    const countAll = compose(
      length,
      pathOr([], ['data', 'ITEMS'])
    )(order)
    const countCanceled = compose(
      length,
      filter(item => includes('GROUP_CANCEL', propOr([], 'ACTIONS', item))),
      pathOr([], ['data', 'ITEMS'])
    )(order)
    return equals(countAll, countCanceled)
  }
)

export const billsSelector = createSelector(orderSelector, order =>
  pathOr([], ['data', 'ITEMS'], order)
)

export const entityBillsSelector = createSelector(orderSelector, order =>
  omit(['ITEMS'], pathOr([], ['data'], order))
)

export const buffersSelector = createSelector(orderSelector, order =>
  pathOr({}, ['data', 'BUFFER'], order)
)

export const billIdsSelector = createSelector(orderSelector, order =>
  compose(
    map(prop('ID')),
    pathOr([], ['data', 'ITEMS'])
  )(order)
)

export const invoicesSelector = createSelector(
  orderSelector,
  storeSelector,
  ({ bufferId }) => bufferId,
  (order, store, bufferId) => compose(
    map(item => ({
      ...item,
      STORE_NAME: pathOr('', [prop('STORE', item), 'NAME'], store)
    })),
    filter(item => prop('BUFFER', item) === bufferId),
    pathOr([], ['data', 'ITEMS'])
  )(order)
)

export const isAutoProcessingSelector = createSelector(
  orderSelector,
  order => compose(
    lte(1),
    length,
    filter(item => propOr(0, 'AUTO_PROCESSING', item)),
    pathOr([], ['data', 'ITEMS'])
  )(order)
)

export const methodDeliverySelector = createSelector(orderSelector, order =>
  pathOr(
    '',
    [
      'data',
      'ENTITIES',
      'DELIVERY',
      path(['data', 'ITEMS', 0, 'DELIVERY', 'TYPE'], order),
      'NAME'
    ],
    order
  )
)

export const addressDeliverySelector = createSelector(orderSelector, order =>
  pathOr(
    '',
    [
      'data',
      'ENTITIES',
      'ADDRESS',
      path(['data', 'ITEMS', 0, 'DELIVERY', 'ADDRESS'], order),
      'ADDRESS'
    ],
    order
  )
)

export const errorOrderSelector = createSelector(orderSelector, order =>
  pathOr('', ['error'], order)
)

export const isErrorPageSelector = createSelector(orderSelector, order =>
  pathOr(false, ['isErrorPage'], order)
)

export const isLoadingOrderSelector = createSelector(orderSelector, order =>
  pathOr(false, ['isLoading'], order)
)

const billSelector = createSelector(
  ({ order }) => order,
  ({ billId }) => billId,
  (orders, billId) => pathOr({}, ['bills', billId], orders)
)

export const billProductsSelector = createSelector(billSelector, order =>
  pathOr([], ['PRODUCT'], order)
)

export const billInfoSelector = createSelector(billSelector, order =>
  pick(['ENTITIES', 'ITEM', 'RECEIPT'], order)
)

export const billLoadingSelector = createSelector(billSelector, order =>
  pathOr(false, ['isLoading'], order)
)

const allInvoicesSelector = createSelector(
  ({ order }) => order,
  order => pathOr({}, ['allInvoices'], order)
)

export const allInvoicesIsLoadingSelector = createSelector(
  allInvoicesSelector,
  allInvoices => pathOr(false, ['isLoading'], allInvoices)
)

export const allInvoicesIsLoadedSelector = createSelector(
  allInvoicesSelector,
  allInvoices => pathOr(false, ['isLoaded'], allInvoices)
)

export const allInvoicesIsErrorSelector = createSelector(
  allInvoicesSelector,
  allInvoices => pathOr(false, ['isError'], allInvoices)
)

export const allInvoicesErrorSelector = createSelector(
  allInvoicesSelector,
  allInvoices => pathOr('', ['error'], allInvoices)
)

export const allInvoicesEntitiesSelector = createSelector(
  allInvoicesSelector,
  allInvoices => pathOr({}, ['data', 'ENTITIES'], allInvoices)
)

export const allInvoicesItemsSelector = createSelector(
  allInvoicesSelector,
  allInvoicesEntitiesSelector,
  (allInvoices, entities) => compose(
    map(item => ({
      ...item,
      'CONTRACTOR_TITLE': pathOr('', ['CONTRACTOR', prop('CONTRACTOR', item), 'TITLE'], entities),
      'ADDRESS_TITLE': pathOr('', ['ADDRESS', prop('ADDRESS', item), 'ADDRESS'], entities),
      'DELIVERY_TITLE': pathOr('', ['DELIVERY', prop('DELIVERY_TYPE', item), 'NAME'], entities)
    })),
    pathOr([], ['data', 'ITEMS'])
  )(allInvoices)
)

export const allInvoicesAddressSelector = createSelector(
  allInvoicesEntitiesSelector,
  allInvoices => compose(
    prepend({ value: '', title: 'Все'}),
    map(item => ({
      value: prop('UF_XML_ID', item),
      title: prop('ADDRESS', item)
    })),
    values,
    pathOr({}, ['ADDRESS'])
  )(allInvoices)
)

export const allInvoicesContractorsSelector = createSelector(
  allInvoicesEntitiesSelector,
  allInvoices => compose(
    prepend({ value: '', title: 'Все'}),
    values,
    mapObjIndexed((item, key) => ({
      value: key,
      title: prop('TITLE', item)
    })),
    pathOr({}, ['CONTRACTOR'])
  )(allInvoices)
)

export const allInvoicesTypeDeliverySelector = createSelector(
  allInvoicesEntitiesSelector,
  allInvoices => compose(
    prepend({ value: '', title: 'Все'}),
    values,
    mapObjIndexed((item, key) => ({
      value: key,
      title: prop('NAME', item)
    })),
    pathOr({}, ['DELIVERY'])
  )(allInvoices)
)
