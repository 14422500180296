/* eslint-disable complexity */
import GoogleTagManager from '@redux-beacon/google-tag-manager'
import { routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, compose } from 'redux'
import { createMiddleware } from 'redux-beacon'
import { install as installReduxLoop } from 'redux-loop'
import thunk from 'redux-thunk'
import io from 'socket.io-client'

import config from 'config'
import { eventsTagManager } from 'helpers/eventsMap'
import createRootReducer from 'redux/modules/reducer'
import createSocketMiddleware from 'redux/socketMiddleware'

const configureStore = (
  initialState,
  history,
  { clientApi, cookie, updateData }
) => {
  const emptyMiddleware = () => next => action => next(action)
  const socketMiddleware =
    config.socket.isActive && typeof window !== 'undefined'
      ? createSocketMiddleware(io('/', config.socket.config))
      : emptyMiddleware
  const gtmMiddleware = config.google.isActive
    ? createMiddleware(eventsTagManager, GoogleTagManager())
    : emptyMiddleware
  const clientMiddleware = () => next => action =>
    next({
      ...action,
      meta: {
        ...action.meta,
        clientApi,
        cookie
      }
    })

  /* eslint-disable */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          shouldHotReload: false
        })
      : compose
  /* eslint-enable */

  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(
      installReduxLoop(),
      applyMiddleware(
        thunk,
        routerMiddleware(history),
        gtmMiddleware,
        socketMiddleware,
        clientMiddleware
      )
    )
  )

  clientApi.setStore(store)
  updateData.setStore(store)

  return store
}

export default configureStore
