import { connect } from 'react-redux'

import Footer from 'components/Footer'
import { basketSelector } from 'redux/modules/basket'
import { loyaltyAccessSelector } from 'redux/modules/loyalty'
import { showModal } from 'redux/modules/modal'
import {
  getPhone,
  getAddress,
  getName,
  getDescription,
  getSiteUrl,
  getOfficesAccess,
  getRegistrationAccess,
  getKancobozId,
  vkUrlSelector,
  fbUrlSelector,
  instagramUrlSelector,
  feedbackFormSelector,
  isLoyaltyMember
} from 'redux/modules/personal'
import {
  isBookmarkSelector,
  isDisabledBodyScrollSelector,
  isLoyaltySelector
} from 'redux/modules/settings'

export default connect(
  ({
    settings,
    basket,
    token: { isUserWithoutPassword },
    catalog: { isVisible, isFixed },
    personal,
    marksMenu: { marks },
    loyalty
  }) => {
    const isLoyalty = isLoyaltySelector(settings)
    const catalog = isLoyalty ? 'main' : 'loyalty'
    const { cnt, sum } = basketSelector({ basket, basketType: catalog })

    return {
      isUserWithoutPassword,
      hasAccessOffices: getOfficesAccess(personal),
      hasAccessRegistration: getRegistrationAccess(personal),
      basketCount: cnt,
      basketSum: sum,
      isLoyaltyAccess: loyaltyAccessSelector(loyalty),
      isLoyaltyMember: isLoyaltyMember(personal),
      kancobozId: getKancobozId(personal),
      phone: getPhone(personal),
      address: getAddress(personal),
      name: getName(personal),
      description: getDescription(personal),
      vkUrl: vkUrlSelector(personal),
      fbUrl: fbUrlSelector(personal),
      feedbackForm: feedbackFormSelector(personal),
      instagramUrl: instagramUrlSelector(personal),
      landingUrl: getSiteUrl(personal),
      catalogMenuIsVisible: isVisible,
      catalogMenuIsFixed: isFixed,
      isDisabledBodyScroll: isDisabledBodyScrollSelector(settings),
      isLoyalty: isLoyaltySelector(settings),
      isBookmark: isBookmarkSelector(settings),
      marks
    }
  },
  {
    showModal
  }
)(Footer)
