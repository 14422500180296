/* eslint-disable complexity */
import { path, pathOr } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'

export {
  getKladr,
  getRegion,
  getRegionId,
  getRegionTitle,
  getDistrict,
  getCity,
  getCityId,
  getCityTitle,
  getDistrictId,
  getDistrictTitle,
  getStreet,
  getStreetId,
  getStreetTitle
} from './selector'

const initialState = {
  isLoading: false,
  error: false,
  isNewZipCode: false,
  params: {},
  items: [],
  address: {},
  parents: [],
  kladr: '',
  region: {
    id: '',
    title: '',
    list: []
  },
  district: {
    id: '',
    title: '',
    list: []
  },
  city: {
    id: '',
    title: '',
    list: []
  },
  street: {
    id: '',
    title: '',
    list: []
  }
}

export const setLocation = createAction('kladr/SET_LOCATION')
export const fetch = createAction('kladr/FETCH')
const fetchSuccess = createAction('kladr/FETCH_SUCCESS')
const fetchFailure = createAction('kladr/FETCH_FAILURE')

export const fetchKladr = createAction('kladr/FETCH_KLADR')
export const onsetKladr = createAction('kladr/ON_SET_KLADR')
export const setKladr = createAction('kladr/SET_KLADR')
export const fetchKladrSuccess = createAction('kladr/FETCH_KLADR_SUCCESS')
export const fetchKladrFailure = createAction('kladr/FETCH_KLADR_FAILURE')

const requestKladr =
  ({ clientApi }) =>
    params =>
      clientApi
        .get('/kladr', { params })
        .then(fetchKladrSuccess)
        .catch(fetchKladrFailure)

const handleFetchKladr = (state, params, { clientApi }) => {
  const kladrLevel = ['region', 'district', 'city', 'street']
  const contentType = path(['contentType'], params)
  const query = path(['query'], params)
  const paramRequest = { limit: 20, contentType, query }
  const currentIndex = kladrLevel.indexOf(contentType)
  const newState = {}
  kladrLevel.map((fieldKladr, indexKladr) => {
    if (indexKladr < currentIndex && path([fieldKladr, 'id'], state)) {
      paramRequest[`${fieldKladr}Id`] = path([fieldKladr, 'id'], state)
    }
    if (indexKladr > currentIndex) {
      newState[fieldKladr] = {
        id: '',
        title: '',
        list: []
      }
    }
    if (indexKladr === currentIndex) {
      newState[fieldKladr] = {
        id: '',
        title: query,
        list: []
      }
    }
    return true
  })

  return loop(
    {
      ...state,
      error: false,
      isLoading: true,
      ...newState
    },
    params
      ? Effects.promise(requestKladr({ clientApi }), paramRequest)
      : Effects.none()
  )
}

const handleOnSetKladr = (state, params) => {
  const kladrLevel = ['region', 'district', 'city', 'street']
  const contentType = path(['contentType'], params)
  const query = path(['query'], params)
  const paramRequest = { limit: 20, contentType, query }
  const currentIndex = kladrLevel.indexOf(contentType)
  const newState = {}
  kladrLevel.map((fieldKladr, indexKladr) => {
    if (indexKladr < currentIndex && path([fieldKladr, 'id'], state)) {
      paramRequest[`${fieldKladr}Id`] = path([fieldKladr, 'id'], state)
    }
    if (indexKladr > currentIndex) {
      newState[fieldKladr] = {
        id: '',
        title: '',
        list: []
      }
    }
    if (indexKladr === currentIndex) {
      newState[fieldKladr] = {
        id: '',
        title: query,
        list: []
      }
    }
    return true
  })

  return {
    ...state,
    error: false,
    isLoading: true,
    ...newState
  }
}

const handleSetKladr = (state, params) => {
  const kladrLevel = ['region', 'district', 'city', 'street']
  const contentType = path(['contentType'], params)
  const currentIndex = kladrLevel.indexOf(contentType)
  const newState = { kladr: path(['id'], params) }
  kladrLevel.map((fieldKladr, indexKladr) => {
    if (indexKladr > currentIndex) {
      newState[fieldKladr] = {
        id: '',
        title: '',
        list: []
      }
    }
    if (indexKladr === currentIndex) {
      newState[fieldKladr] = {
        id: path(['id'], params),
        title: path(['title'], params),
        list: []
      }
    }
    return true
  })
  return {
    ...state,
    ...newState
  }
}

const handleFetchKladrSuccess = (state, payload) => {
  const list = path(['data', 'result'], payload)
  const title = path(['data', 'searchContext', 'query'], payload)
  const contentType = path(['data', 'searchContext', 'contentType'], payload)
  return {
    ...state,
    [contentType]: {
      id: '',
      title,
      list
    }
  }
}

const handleFetchKladrFailure = state => ({ ...state })

const request =
  ({ clientApi }) =>
    params =>
      clientApi.get('/kladr', { params }).then(fetchSuccess).catch(fetchFailure)

const handleFetch = (state, params, { clientApi }) =>
  loop(
    {
      ...state,
      error: false,
      isLoading: true,
      items: params ? [...state.items] : [],
      isNewZipCode: (params || {}).isNewZipCode
    },
    params ? Effects.promise(request({ clientApi }), params) : Effects.none()
  )

const handleFetchSuccess = (state, payload) => {
  const params = pathOr('', ['config', 'params'], payload)
  const { parents } = pathOr([], ['data', 'result'], payload)[0] || {}
  return {
    ...state,
    isLoading: false,
    parents,
    items: pathOr([], ['data', 'result'], payload).map(
      ({ id, name, type, zip }) => ({
        value: id,
        title: name,
        type:
          ['region', 'district'].indexOf(params.contentType) === -1
            ? null
            : type,
        zip
      })
    ),
    params
  }
}

const handleFetchFailure = (state, payload) => ({
  ...state,
  isLoading: false,
  items: [],
  error: getErrorMessage(payload)
})

const handleSetLocatin = (state, payload) => ({
  ...state,
  isNewZipCode: false,
  address: {
    ...state.address,
    ...payload
  }
})

const reducer = createReducer(on => {
  on(fetch, handleFetch)
  on(fetchSuccess, handleFetchSuccess)
  on(fetchFailure, handleFetchFailure)
  on(setLocation, handleSetLocatin)

  on(setKladr, handleSetKladr)
  on(onsetKladr, handleOnSetKladr)
  on(fetchKladr, handleFetchKladr)
  on(fetchKladrSuccess, handleFetchKladrSuccess)
  on(fetchKladrFailure, handleFetchKladrFailure)
}, initialState)

export default reducer
