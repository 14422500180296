import Big from 'big.js'

const roundCurrency = value => Big(value)

const addCurrency = (value1, value2) => Big(value1).add(value2)

const minusCurrency = (value1, value2) => Big(value1).minus(value2)

const multiplyCurrency = (value1, value2) => Big(value1).mul(value2)

const divisionCurrency = (value1, value2) => Big(value1).div(value2)

export {
  roundCurrency,
  multiplyCurrency,
  divisionCurrency,
  addCurrency,
  minusCurrency
}
