import React, { useEffect } from 'react'

import { func, object, string } from 'prop-types'
import { prop } from 'ramda'
import { connect } from 'react-redux'

import config from 'config'
import Modal from 'containers/Modal'
import { showModal } from 'redux/modules/modal'
import { currentContractorGuidSelector, currentContractorNameSelector, getUserEmail } from 'redux/modules/personal'
import Title from 'UI/Title'

import s from './FormIo.scss'
import Form from './FormWrapper'
import Product from './Product'

const ModalForm = ({ contractorGuid, contractorName, userMail, product, onShowModal, onClose }) => {
  useEffect(() => {
    onShowModal('productReview')
  }, [])

  const onReady = () => formInstance => {
    const instanceProductId = formInstance.getComponent('product_id')
    const instanceProductGuid = formInstance.getComponent('product_guid')
    const instanceContractor = formInstance.getComponent('contractor')
    const instanceContractorName = formInstance.getComponent('contractor_name')
    const instanceUserMail = formInstance.getComponent('user_mail')
    instanceProductId.setValue(prop('ID', product))
    instanceProductGuid.setValue(prop('XML_ID', product))
    instanceContractor.setValue(contractorGuid)
    instanceContractorName.setValue(contractorName)
    instanceUserMail.setValue(userMail)
  }

  return (
    <Modal id='productReview'
      onClose={onClose}>
      <div className={s.container}
        itemProp='review'>
        <Title>Отзыв на товар</Title>
        <Product product={product} />
        <Form onClose={onClose}
          src={`${config.formIo.host}/productreview`}
          formReady={onReady()}
        />
      </div>
    </Modal>
  )
}

ModalForm.propTypes = {
  product: object,
  onShowModal: func,
  onClose: func,
  contractorGuid: string,
  contractorName: string,
  userMail: string
}

export default connect(
  ({ personal }) => ({
    contractorGuid: currentContractorGuidSelector(personal),
    contractorName: currentContractorNameSelector(personal),
    userMail: getUserEmail(personal)
  }),
  {
    onShowModal: showModal
  }
)(ModalForm)
