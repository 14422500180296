import React, { useCallback, useState } from 'react'

import { object } from 'prop-types'

import config from 'config'
import Button from 'UI/Button'
import Spacing from 'UI/Spacing'

import ModalForm from './CheaperModal'

const ButtonForm = ({ product }) => {
  const [isOpen, setIsOpen] = useState(false)

  const onOpen = useCallback(() => setIsOpen(true), [])

  const onClose = useCallback(() => setIsOpen(false), [])

  if (!config.formIo.isActive) {
    return null
  }

  return (
    <>
      <Spacing margin='top'>
        <Button
          color='whiteBlue'
          onClick={onOpen}
          stretched
        >
          Нашли дешевле?
        </Button>
      </Spacing>
      {isOpen && <ModalForm product={product}
        onClose={onClose} />}
    </>
  )
}

ButtonForm.propTypes = {
  product: object
}

export default ButtonForm
