import React, { useCallback, useState } from 'react'

import { string } from 'prop-types'

import config from 'config'
import Button from 'UI/Button'
import Spacing from 'UI/Spacing'

import ModalForm from './ExpansionProductsModal'

const ButtonForm = ({ text }) => {
  const [isOpen, setIsOpen] = useState(false)

  const onOpen = useCallback(() => setIsOpen(true), [])

  const onClose = useCallback(() => setIsOpen(false), [])

  if (!config.formIo.isActive) {
    return null
  }

  return (
    <>
      <Spacing margin='vertical'>
        <Button
          color='blue'
          size='big'
          shape='flat'
          onClick={onOpen}>
          {text}
        </Button>
      </Spacing>
      {isOpen && <ModalForm onClose={onClose} />}
    </>
  )
}

ButtonForm.propTypes = {
  text: string
}

export default ButtonForm
